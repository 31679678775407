import { useMemo } from 'react';

import { calculateRisk, MitigationValue } from '../../components/RiskForm/formHelpers';
import { RiskQualityTypeConfig } from '../../types/qualityTypeConfig';

export const useIsoRiskCalculateScore = (
  risk: RiskQualityTypeConfig,
  mitigationLevel: 'preMitigation' | 'postMitigation',
  watchValue: Record<string, string>,
): string | undefined => {
  return useMemo(() => {
    const probabilityValue = watchValue[`${mitigationLevel}.probability`];
    const severityValue = watchValue[`${mitigationLevel}.severity`];

    if (!probabilityValue || !severityValue) {
      return undefined;
    }

    const probability = risk.assessment.iso.probability;
    const severity = risk.assessment.iso.severity;

    const mitigation: MitigationValue = {
      probability: probability.findIndex((value) => value === probabilityValue),
      severity: severity.findIndex((value) => value === severityValue),
    };

    return calculateRisk(risk, mitigation);
  }, [risk, watchValue, mitigationLevel]);
};
