import React, { useState } from 'react';

import { QIconButton, QStack } from '@qualio/ui-components';
import { ColumnsType, ExpandableConfig } from 'antd/lib/table/interface';
import { useNavigate, useParams } from 'react-router-dom';

import BorderedValue from '../../../../components/BorderedValue';
import Req1Icon from '../../../../components/CustomIcons/Req1';
import { DeleteConfirmationModal } from '../../../../components/DeleteDesignElementModal/DeleteConfirmationModal';
import { FeatureFlags } from '../../../../components/FeatureToggle/FeatureToggle.enum';
import useFeatureFlag from '../../../../components/FeatureToggle/hooks/useFeatureFlag';
import ItemCode from '../../../../components/ItemCode';
import StyledTable from '../../../../components/StyledTable';
import commonTableStyles from '../../../../components/StyledTable/commonStyles.module.less';
import useHasPermission from '../../../../components/UserGroupToggle/hooks/useHasPermission';
import { UserPermissions } from '../../../../components/UserGroupToggle/UserGroupToggle.enum';
import { useDrawerProvider } from '../../../../components_new/DesginElementDetailsDrawer/context/useDrawerProvider';
import { useLocalisingDateFormatter } from '../../../../lib/dateFormat';
import { designControlStatus } from '../../../../lib/designControlStatus';
import { sortByCode } from '../../../../lib/sortByCode';
import { isSoureEditableAndDeletable } from '../../../../lib/source';
import { ProductParam } from '../../../../paramTypes';
import { BaseQualityItem, DesignElementType } from '../../../../types/baseQualityItem';
import { RequirementTrace } from '../../../../types/trace';
import ExpandedRow from './ExpandedRow/ExpandedRow';
import styles from './RequirementTraceTable.module.less';

type Props = {
  isLoading: boolean;
  items: RequirementTrace[];
  onDelete: (id: string, type: DesignElementType) => Promise<void>;
};

const RequirementTraceTable: React.FC<Props> = ({ isLoading, items, onDelete }) => {
  const { product } = useParams<ProductParam>() as ProductParam;
  const navigate = useNavigate();
  const [designElementToBeDeleted, setDesignElementToBeDeleted] = useState<BaseQualityItem | undefined>();
  const permissions = useHasPermission([UserPermissions.EDIT_DESIGN_ELEMENTS, UserPermissions.DELETE_DESIGN_ELEMENTS]);
  const dateFormat = useLocalisingDateFormatter();
  const { handleItemSelected } = useDrawerProvider();
  const features = useFeatureFlag([FeatureFlags.SEARCH, FeatureFlags.SELF_SERVICE]);

  const editDeleteActionsColumn = {
    render: (text: any, record: any) => {
      if (!isSoureEditableAndDeletable(record.source, features[FeatureFlags.SELF_SERVICE])) {
        return null;
      }

      return (
        <QStack justifyContent={'space-evenly'} direction={'row'}>
          <QIconButton
            iconName={'Edit2'}
            aria-label={'Edit'}
            data-cy={`edit-requirement-${record.id}`}
            onClick={() => navigate(`/product/${product}/requirement/edit/${record.id}`)}
          />
          <QIconButton
            iconName={'Trash'}
            aria-label={'Delete'}
            data-cy={`delete-requirement-${record.id}`}
            onClick={() => deleteDesignElement(record)}
          />
        </QStack>
      );
    },
    width: 40,
  };

  const columns: ColumnsType<RequirementTrace> = [
    {
      title: 'Requirement',
      dataIndex: 'title',
      render: (title, item) => (
        <span
          className={`${commonTableStyles.primaryColumn} ${styles.requirement} ${
            features[FeatureFlags.SEARCH] ? commonTableStyles.clickTitle : ''
          }`}
          onClick={features[FeatureFlags.SEARCH] ? () => handleItemSelected(item.id) : undefined}
        >
          <aside className={commonTableStyles.primaryColumnIcon}>
            <Req1Icon style={{ fontSize: 22 }} />
          </aside>
          <main className={commonTableStyles.primaryColumnContent}>
            {item.code && <ItemCode code={item.code} />} <strong>{title}</strong>
          </main>
        </span>
      ),
      defaultSortOrder: 'ascend',
      sorter: sortByCode,
    },
    {
      title: 'Last updated',
      dataIndex: 'updated',
      render: (updated) => dateFormat(updated),
    },
    {
      title: 'Tests',
      className: `${styles.topWorkflowColumn} ${commonTableStyles.rightAlignedColumn}`,
      dataIndex: 'testedBy',
      render: (testedBy) => {
        if (!testedBy || !testedBy.length) {
          return null;
        }

        return <BorderedValue borderType={'circle'}>{testedBy.length}</BorderedValue>;
      },
      width: 80,
    },
    {
      title: 'Status',
      dataIndex: 'statusLabel',
      className: `${styles.topWorkflowColumn} ${commonTableStyles.rightAlignedColumn}`,
      render: (label) => <BorderedValue borderType={'rectangle'}>{label}</BorderedValue>,
    },
    {
      title: 'Design Control',
      className: `${styles.topWorkflowColumn} ${commonTableStyles.rightAlignedColumn}`,
      render: (item: BaseQualityItem) => (
        <BorderedValue borderType={'rectangle'}>{designControlStatus(item)}</BorderedValue>
      ),
    },
  ];

  if (permissions[UserPermissions.EDIT_DESIGN_ELEMENTS] && permissions[UserPermissions.DELETE_DESIGN_ELEMENTS]) {
    columns.push(editDeleteActionsColumn);
  }

  const expandable: ExpandableConfig<RequirementTrace> = {
    expandedRowRender: (record) => <ExpandedRow requirement={record} onDelete={deleteDesignElement} />,
  };

  const deleteDesignElement = (designElement: BaseQualityItem) => {
    setDesignElementToBeDeleted(designElement);
  };

  const handleOnDeleteConfirmation = () => {
    if (designElementToBeDeleted) {
      onDelete(designElementToBeDeleted.id, designElementToBeDeleted.type);
      setDesignElementToBeDeleted(undefined);
    }
  };
  return (
    <>
      <StyledTable
        childrenColumnName="disabled"
        isLoading={isLoading}
        columns={columns}
        rowKey="id"
        dataSource={items}
        expandable={expandable}
        className={styles.topLevelTable}
      />
      {!isLoading && (
        <DeleteConfirmationModal
          onConfirm={handleOnDeleteConfirmation}
          onClose={() => setDesignElementToBeDeleted(undefined)}
          confirmationDetails={
            designElementToBeDeleted
              ? {
                  title: 'Delete design element',
                  message: (
                    <>
                      Are you sure you want to delete&nbsp;
                      <b>
                        {designElementToBeDeleted?.code} - {designElementToBeDeleted?.title}?
                      </b>
                    </>
                  ),
                }
              : undefined
          }
        />
      )}
    </>
  );
};

export default RequirementTraceTable;
