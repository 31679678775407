import React from 'react';

import { QButton, QIconButton, QInput, QSelect, QText } from '@qualio/ui-components';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

import { triggerFieldValuesValidation } from '../../lib/formValidationUtilities';
import { Container, Mark, Slider, SliderContainer, Table, Titles } from './RiskLevel.styles';
import { riskLevelColorsQTheme } from './riskLevelColors';

type RiskLevelProps = {
  riskLevelFieldName: string;
  levelMappingFieldName: string;
};

const RiskLevel: React.FC<RiskLevelProps> = ({ riskLevelFieldName, levelMappingFieldName }) => {
  const { control, setValue, watch, getValues, errors, trigger } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: riskLevelFieldName,
  });
  const watchRiskLevels = watch(riskLevelFieldName);
  const handleChangeRequiresMigration = (value: string, field: any) => {
    const localRiskLevels = [...fields];
    const index = localRiskLevels.findIndex((item) => item.id === field.id);
    if (value === 'Yes') {
      if (index <= localRiskLevels.length) {
        for (let i = index; i < localRiskLevels.length; i++) {
          localRiskLevels[i].requiresMitigation = 'Yes';
        }
      }
    } else {
      localRiskLevels[index].requiresMitigation = 'No';
    }
    setValue(riskLevelFieldName, localRiskLevels);
  };

  const handleRiskLevelDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldId: string | undefined,
    oldValue: string,
    defaultOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  ) => {
    const { levelMapping } = getValues();
    const {
      target: { value: newValue },
    } = event;
    let localLevelMapping = [...levelMapping];
    localLevelMapping = localLevelMapping.map((row) =>
      row.map((cell: string) => {
        return cell === oldValue ? newValue : cell;
      }),
    );
    defaultOnChange(event);
    setValue(levelMappingFieldName, localLevelMapping);
    const localRiskLevels = [...fields];
    const index = localRiskLevels.findIndex((item) => item.id === fieldId);
    if (index > -1) {
      localRiskLevels[index].label = newValue;
      setValue(riskLevelFieldName, localRiskLevels);
    }
  };

  const requiresMitigation = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  return (
    <Container>
      <Table width="85%">
        <thead>
          <tr className="row">
            <td className="col col-1">
              <QText fontSize="sm" fontWeight={'semibold'}>
                Risk Level
              </QText>
            </td>
            <td className="col col-2">
              <QText fontSize="sm" fontWeight={'semibold'}>
                Requires Mitigation?
              </QText>
            </td>
            <td className="col col-3" />
          </tr>
        </thead>
        <tbody>
          {fields.map((field, index) => {
            return (
              <tr className="row" key={field.id}>
                <td className="col col-1">
                  <Controller
                    render={({ onChange, value }) => (
                      <QInput
                        data-cy="risk-level"
                        defaultValue={value}
                        onBlur={() => triggerFieldValuesValidation(fields.length, riskLevelFieldName, trigger)}
                        onChange={(e) => handleRiskLevelDescriptionChange(e, field.id, value, onChange)}
                      />
                    )}
                    name={`${riskLevelFieldName}[${index}].label`}
                    defaultValue={field.label}
                  />
                  {errors?.[riskLevelFieldName]?.[index] && (
                    <QText fontSize="xs" color="red.900" data-cy={'risk-level-error'}>
                      {errors?.[riskLevelFieldName]?.[index].label.message}
                    </QText>
                  )}
                </td>
                <td data-cy="requires-mitigation" className="col col-2">
                  {watchRiskLevels && (
                    <Controller
                      name={`${riskLevelFieldName}[${index}].requiresMitigation`}
                      defaultValue={field.requiresMitigation}
                      render={({ onChange, value }) => (
                        <QSelect
                          options={requiresMitigation}
                          value={requiresMitigation.find((item) => item.value === value)?.value}
                          onChange={(e: any) => {
                            handleChangeRequiresMigration(e.value, field);
                          }}
                          filterOption={null}
                          isDisabled={fields[index - 1]?.requiresMitigation === 'Yes'}
                          isLoading={false}
                          isSearchable={false}
                        />
                      )}
                    />
                  )}
                </td>
                <td className="col col-3">
                  <QIconButton
                    iconName={'Trash'}
                    aria-label={'Remove risk level'}
                    onClick={() => remove(index)}
                    data-cy={`remove-risk-level-button`}
                    isDisabled={fields.length <= 2}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>

        <tfoot>
          <tr className="row">
            <td>
              <QButton
                variant="link"
                rightIcon="Plus"
                data-cy="add-risk-level-button"
                isDisabled={fields.length > 4}
                onClick={() =>
                  append({
                    label: '',
                    requiresMitigation: fields[fields.length - 1].requiresMitigation,
                  })
                }
              >
                Add
              </QButton>
            </td>
          </tr>
        </tfoot>
      </Table>
      <SliderContainer>
        <Slider marks={fields.length} data-cy="risk-level-color-slider">
          {fields.map((item, index) => {
            const color = riskLevelColorsQTheme[fields.length][index];
            return <Mark key={`mark_${index}`} backgroundColor={color} data-cy={`risk-level-color-marker-${color}`} />;
          })}
        </Slider>
        <Titles>
          {['Less critical', 'More critical'].map((item, index) => (
            <QText fontSize="xs" key={`critical_${index}`}>
              {item}
            </QText>
          ))}
        </Titles>
      </SliderContainer>
    </Container>
  );
};

export default RiskLevel;
