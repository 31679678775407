import React, { useCallback, useEffect, useMemo } from 'react';

import { useDisclosure } from '@chakra-ui/hooks';
import {
  QBox,
  QButton,
  QLookup,
  QModalHeader,
  QResourceLabel,
  QSpinner,
  QStack,
  QText,
  QTitle,
} from '@qualio/ui-components';
import { SelectableDataAccessors } from '@qualio/ui-components/lib/QOrganisms/Lookup/types';
import { ControllerRenderProps } from 'react-hook-form';

import { globalSearchApi } from '../../api/global-search';
import { qriApi } from '../../api/qri';
import { useRetrieveQriDetails } from '../../hooks/qri/useRetrieveQriDetails';
import { Category } from '../../types/baseConfiguration';

export type ResourceLibraryLookupProps = Omit<ControllerRenderProps, 'ref'> & {
  'data-cy': string;
  resource: 'registry-category' | 'registry-item';
  resourceSubType?: string;
  title: string;
  action: string;
};

export const ResourceLibraryLookup: React.FC<ResourceLibraryLookupProps> = ({
  onChange,
  value,
  'data-cy': dataCy,
  resource,
  resourceSubType,
  title,
  action,
  ...rest
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const defaultDataQRIs = useMemo(() => (value ?? []).map((item: Category) => item.qri), [value]);

  const { isLoading: isLoadingQriDetails, mutate, data: qriDetails } = useRetrieveQriDetails();

  useEffect(() => {
    if (defaultDataQRIs.length === 0) {
      return;
    }
    mutate(defaultDataQRIs);
  }, [defaultDataQRIs, mutate]);

  const isPreselected = useCallback(
    (result: QLookup.DataProvider.V2Result) => {
      return !!defaultDataQRIs.find((qri: string) => qri === result.identifiers.qri);
    },
    [defaultDataQRIs],
  );

  const onSelect = useCallback(
    (selection: readonly QLookup.DataProvider.V2Result[]) => {
      if (!selection || selection.length === 0) {
        //When selection is cleared, set category value to be undefined instead of an empty array
        onChange(undefined);
      } else {
        const mappedSelection = selection.map((item) => ({
          label: item.displayLabel,
          resourceIdentifier: item.identifiers.matrixId,
          qri: item.identifiers.qri,
        }));
        onChange(mappedSelection);
      }
      onClose();
      return Promise.resolve();
    },
    [onClose, onChange],
  );

  return (
    <>
      {!!value ? (
        (value ?? []).map((element: Category) =>
          !isLoadingQriDetails ? (
            <QStack key={element.resourceIdentifier} direction="row">
              <QResourceLabel domain="registry">
                <QText fontSize="sm">{qriDetails?.[element.qri]?.displayLabel || element.label}</QText>
              </QResourceLabel>
              ))
              <QButton variant="link" onClick={onOpen} data-cy={dataCy}>
                Edit
              </QButton>
            </QStack>
          ) : (
            <QBox key={element.resourceIdentifier} alignItems="left">
              <QSpinner size="sm" data-cy="qri-details-loading-spinner" />
            </QBox>
          ),
        )
      ) : (
        <QButton variant="link" onClick={onOpen} data-cy={dataCy}>
          Search for resource
        </QButton>
      )}
      {isOpen ? (
        <QLookup.DataProvider.QuickSearchV2
          quickSearchClient={globalSearchApi}
          qriClient={qriApi}
          resource={resource}
          defaultDataQRIs={defaultDataQRIs}
          resourceSubType={resourceSubType}
        >
          <QLookup.SingleSelect
            isOpen={isOpen}
            onCancel={onClose}
            onSelect={onSelect}
            accessors={ACCESSORS}
            view={CATEGORIES_DATA_VIEW}
            isItemPreSelected={isPreselected}
            data-cy={`${dataCy}-lookup`}
            action={action}
          >
            <QModalHeader>
              <QTitle>{title}</QTitle>
            </QModalHeader>
          </QLookup.SingleSelect>
        </QLookup.DataProvider.QuickSearchV2>
      ) : null}
    </>
  );
};

const ACCESSORS = {
  id: 'id',
} as Pick<SelectableDataAccessors<QLookup.DataProvider.V2Result>, 'id'>;

const CATEGORIES_DATA_VIEW = {
  displayLabel: {
    header: 'Category',
    width: '100%',
    render: (displayLabel) => <QText>{displayLabel}</QText>,
  },
} as QLookup.DataView<QLookup.DataProvider.V2Result>;
