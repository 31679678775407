import { QriDetails, QriDetailsSchema } from '../../types/qri';
import { qriApi } from './index';

export const QriAPI = {
  async retrieveDetails(qris: string[]): Promise<QriDetails> {
    const response = await qriApi.post('/details', {
      qris,
      short: false,
    });

    return QriDetailsSchema.parse(response.data);
  },
} as const;
