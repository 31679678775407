import React, { useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import {
  QAttachment,
  QAttachments,
  QBadge,
  QBox,
  QButton,
  QCollapse,
  QFormControl,
  QIconButton,
  QInput,
  QSelect,
  QSelectItem,
  QTable,
  QTbody,
  QTd,
  QTr,
  useToastProvider,
} from '@qualio/ui-components';
import { Controller, ControllerRenderProps, FormProvider, useForm } from 'react-hook-form';

import { attachmentsApi } from '../../../api/attachmentsApi';
import { QAttachmentsView } from '../../../components/AttachmentsView/attachmentsView';
import { useCompanyId } from '../../../context/CurrentUserContext';
import { AttachmentEventsHelper } from '../../../lib/attachments/attachmentEventsHelper';
import { downloadFile } from '../../../lib/downloadFile';
import { TestResult, TestResultSubmission, TestResultType } from '../../../types/testResult';
import { TestCaseTrace } from '../../../types/trace';
import { useTestResultMutation } from '../hooks/useTestResult';
import './TestResultRow.styles.less';

type TestResultRowProps = {
  productId: string;
  testCase: TestCaseTrace;
  options: Array<QSelectItem>;
  selectedItemId?: string;
};

enum FormState {
  ENABLED,
  DISABLED,
  LOADING,
}

const TestResultRow: React.FC<TestResultRowProps> = ({ productId, testCase, options, selectedItemId }) => {
  const methods = useForm<TestResultType>({
    defaultValues: { result: undefined, executedBy: '', notes: '' },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(TestResult),
  });
  const {
    formState: { errors },
  } = methods;

  const [formSaved, setFormSave] = useState(FormState.ENABLED);
  const [attachmentsToggle, setAttachmentsToggle] = useState<boolean>(false);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<Array<QAttachment>>([]);
  const companyId = useCompanyId();
  const { showToast } = useToastProvider();
  const { saveTestResult } = useTestResultMutation(
    productId,
    () => {
      setFormSave(FormState.DISABLED);
    },
    () => {
      setFormSave(FormState.ENABLED);
    },
  );

  const handleSubmit = (data: TestResultType) => {
    setFormSave(FormState.LOADING);
    setAttachmentsToggle(false);
    const testResultPayload: TestResultSubmission = {
      ...data,
      result: data.result.value,
      testCase: testCase.id,
      codeTitle: testCase.codeTitle ?? '',
    };
    testResultPayload.attachments = attachments;

    saveTestResult(testResultPayload);
  };

  const Input = (props: ControllerRenderProps<Record<string, any>>) => (
    <QInput
      isDisabled={formSaved !== FormState.ENABLED}
      onChange={props.onChange}
      value={props.value}
      data-cy={props.name}
    />
  );

  return (
    <QTr data-cy={`test-case-${testCase.id}`} className="TestResultRow">
      <QTd colSpan={7}>
        <FormProvider {...methods}>
          <QTable className="TestResultRow-table">
            <QTbody>
              <QTr className={selectedItemId === testCase.id ? 'selected' : ''}>
                <QTd
                  className="clickable"
                  width="95px"
                  valign={'top'}
                  data-cy={'badge'}
                  data-drawer={testCase.id}
                  rowSpan={2}
                >
                  <QBadge>{testCase.code}</QBadge>
                </QTd>
                <QTd
                  className="clickable"
                  width={'30%'}
                  valign={'top'}
                  data-cy={'title'}
                  data-drawer={testCase.id}
                  rowSpan={2}
                >
                  {testCase.title}
                </QTd>
                <QTd width="105px" valign={'top'} data-cy={'result'}>
                  <QFormControl isInvalid={!!errors.result} error={(errors.result as any)?.message}>
                    <Controller
                      name={'result'}
                      render={(props) => (
                        <QSelect
                          isDisabled={formSaved === FormState.DISABLED}
                          options={options}
                          onChange={props.onChange}
                          value={props.value}
                        />
                      )}
                    />
                  </QFormControl>
                </QTd>
                <QTd width="180px" valign={'top'}>
                  <QFormControl isInvalid={!!errors.executedBy} error={errors.executedBy?.message}>
                    <Controller name={'executedBy'} render={Input} />
                  </QFormControl>
                </QTd>
                <QTd valign={'top'}>
                  <QFormControl isInvalid={!!errors.notes} error={errors.notes?.message}>
                    <Controller name={'notes'} render={Input} />
                  </QFormControl>
                </QTd>
                <QTd width="38px" valign={'top'}>
                  <QIconButton
                    data-cy="attachments-button"
                    aria-label="attachments-button"
                    iconName="Paperclip"
                    size="sm"
                    onClick={() => setAttachmentsToggle(!attachmentsToggle)}
                    isDisabled={formSaved === FormState.DISABLED && attachments.length === 0}
                  />
                </QTd>

                <QTd width="60px" valign={'top'}>
                  <QButton
                    data-cy="test-result-submit"
                    isDisabled={formSaved === FormState.DISABLED || isBusy}
                    isLoading={formSaved === FormState.LOADING}
                    onClick={methods.handleSubmit(handleSubmit)}
                  >
                    Save
                  </QButton>
                </QTd>
              </QTr>
              <QTr className="attachments">
                <QTd colSpan={4}>
                  <QCollapse isOpen={attachmentsToggle}>
                    {formSaved === FormState.ENABLED ? (
                      <QAttachments
                        isBusy={setIsBusy}
                        attachments={attachments}
                        itemInsertLocation={(elA, elB) => elA.filename?.localeCompare(elB.filename)}
                        uploadInfoProvider={attachmentsApi.uploadInfoProvider(companyId, productId)}
                        onAdd={(attachment: QAttachment) => {
                          setAttachments([...attachments, attachment]);
                          return Promise.resolve();
                        }}
                        onClick={(attachmentId: string) => {
                          attachmentsApi.downloadAttachment(companyId, productId, attachments, attachmentId);
                          return Promise.resolve();
                        }}
                        onRemove={(attachmentId: string) => {
                          setAttachments(attachments.filter((a) => a.id !== attachmentId));
                          return Promise.resolve();
                        }}
                        onRevert={(attachmentId: string) => {
                          setAttachments(attachments.filter((a) => a.id !== attachmentId));
                          return Promise.resolve();
                        }}
                        onWarning={(warning) => AttachmentEventsHelper.handleWarning(warning, showToast)}
                        maxFiles={5}
                        maxFileSize="100MB"
                        maxTotalFileSize="200MB"
                      />
                    ) : (
                      <QBox mb="15px">
                        <QAttachmentsView
                          attachments={attachments}
                          sort={(elA, elB) => elA.filename?.localeCompare(elB.filename)}
                          onClick={(attachmentId: string) =>
                            downloadFile(attachmentsApi.getDownloadUrl(companyId, productId, attachmentId))
                          }
                        />
                      </QBox>
                    )}
                  </QCollapse>
                </QTd>
                <QTd />
              </QTr>
            </QTbody>
          </QTable>
        </FormProvider>
      </QTd>
    </QTr>
  );
};

export default TestResultRow;
