import { BaseQualityItem } from './baseQualityItem';

export enum PolicyIssues {
  REQUIRES_PARENT = 'REQUIRES_PARENT',
  UNMITIGATED = 'UNMITIGATED',
  REQUIRES_CHILD = 'REQUIRES_CHILD',
  UNTESTED_REQUIREMENT = 'UNTESTED_REQUIREMENT',
  FAILING_TEST = 'FAILING_TEST',
  OUTDATED_TESTRESULT = 'OUTDATED_TESTRESULT',
}

export const PolicyIssueLabels: Record<PolicyIssues, string> = {
  [PolicyIssues.REQUIRES_CHILD]: 'Missing links',
  [PolicyIssues.FAILING_TEST]: 'Failing tests',
  [PolicyIssues.REQUIRES_PARENT]: 'Orphaned',
  [PolicyIssues.UNMITIGATED]: 'Unmitigated risks',
  [PolicyIssues.UNTESTED_REQUIREMENT]: 'Untested requirements',
  [PolicyIssues.OUTDATED_TESTRESULT]: 'Outdated test result',
};

export type OpenIssue = BaseQualityItem & {
  policyIssues: Record<PolicyIssues, boolean>;
};
