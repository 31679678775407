import React, { useCallback } from 'react';

import { QFormControl, QSelect } from '@qualio/ui-components';
import { useFormContext, useWatch } from 'react-hook-form';

import { RiskConfigurationType } from '../../../types/riskConfiguration';
import {
  riskLevelBlue,
  riskLevelGreen,
  riskLevelOrange,
  riskLevelRed,
  riskLevelYellow,
} from '../../RiskLevel/riskLevelColors';
import { LevelSelectorContainer } from '../RiskMatrix.styles';

type LevelMappingProps = {
  riskLevelFieldName: string;
  onChange: (...event: any[]) => void;
  value: string[][];
};

const riskColors: Record<number, string[]> = {
  2: [`${riskLevelBlue}`, `${riskLevelRed}`],
  3: [`${riskLevelBlue}`, `${riskLevelYellow}`, `${riskLevelRed}`],
  4: [`${riskLevelBlue}`, `${riskLevelGreen}`, `${riskLevelYellow}`, `${riskLevelRed}`],
  5: [`${riskLevelBlue}`, `${riskLevelGreen}`, `${riskLevelYellow}`, `${riskLevelOrange}`, `${riskLevelRed}`],
};

const LevelMapping: React.FC<LevelMappingProps> = ({ riskLevelFieldName, value, onChange }) => {
  const riskLevels = useWatch<RiskConfigurationType['riskLevels']>({
    name: riskLevelFieldName,
    defaultValue: [],
  });

  const {
    formState: { errors },
  } = useFormContext();

  const onMappedItemChange = useCallback(
    (itemValue: string, row: number, column: number) => {
      value[row][column] = itemValue;
      onChange([...value]);
    },
    [value, onChange],
  );

  const options = riskLevels.map((value) => ({ value: value.label, label: value.label }));

  const colour = useCallback(
    (fieldValue?: string) => {
      const index = options.findIndex((value) => value.value === fieldValue);
      if (index < 0) {
        return '';
      }

      return riskColors[riskLevels.length][index];
    },
    [riskLevels, options],
  );

  const hasError = useCallback(
    (rowIndex: number, columnIndex: number): boolean => {
      if (!errors.levelMapping) {
        return false;
      }

      if (!errors.levelMapping[rowIndex]) {
        return false;
      }

      return errors.levelMapping[rowIndex][columnIndex] !== undefined;
    },
    [errors],
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {value?.map((row, rowIndex) => (
        <div style={{ display: 'flex' }} key={`levelMappingRow_${rowIndex}`}>
          {row.map((item, columnIndex) => (
            <LevelSelectorContainer
              className="item"
              key={`levelMapping_${rowIndex}_${columnIndex}`}
              color={colour((options.find((option) => option.value === item) || { value: '', label: '' }).value)}
            >
              <QFormControl label={''} isInvalid={hasError(rowIndex, columnIndex)}>
                <QSelect
                  key={`levelMappingField_${rowIndex}_${columnIndex}`}
                  options={options}
                  filterOption={null}
                  isDisabled={false}
                  isLoading={false}
                  isSearchable={false}
                  value={(options.find((option) => option.value === item) || { value: '', label: '' }).value}
                  onChange={(record: any) => record && onMappedItemChange(record.value, rowIndex, columnIndex)}
                />
              </QFormControl>
            </LevelSelectorContainer>
          ))}
        </div>
      ))}
    </div>
  );
};

export default LevelMapping;
