import React from 'react';

import { QAlert, QAttachments, QBox, QFormControl, QInput, QSelect } from '@qualio/ui-components';
import { Controller } from 'react-hook-form';

import {
  BasicForm,
  BasicFormSectionHeader,
  Editor,
  HorizontalLine,
  InputLabel,
  RelatedElements,
  SegmentedControl,
} from '../../../components';
import { SearchElementType } from '../../../components/SearchField/SearchField';
import { RequirementsType } from '../../../types/baseQualityItem';
import { NativeTestCasePresentationalProps } from '../types/NativeTestCase.types';

const NativeTestCasePresentational: React.FC<NativeTestCasePresentationalProps> = ({
  title,
  confirmText,
  product,
  isLoading,
  testCaseCode,
  testCaseSegmentedControlTypes,
  testCaseStatuses,
  relatedRequirement,
  isSegmentedControlDisabled,
  resetFormValuesOnTestCaseTypeChange,
  cancelCreateTestCase,
  confirmCreateTestCase,
  deleteLinkedRequirements,
  isSubmittingPayload,
  formErrors,
  testResult,
  existingAttachments,
  isBusy,
  setIsBusy,
  uploadInfoProvider,
  onAttachmentAdd,
  onAttachmentClick,
  onAttachmentRemove,
  onAttachmentWarning,
}) => {
  const alerts = [];
  if (!!Object.keys(formErrors).length) {
    alerts.push('Please fill out all the form');
  }
  return (
    <BasicForm
      title={title}
      product={product}
      isError={false}
      isLoading={isLoading}
      cancelText="Cancel"
      confirmText={confirmText}
      confirmButtonLoading={isSubmittingPayload}
      onCancel={cancelCreateTestCase}
      onConfirm={confirmCreateTestCase}
      isBusy={isBusy}
    >
      {alerts.map((alert, index) => (
        <QBox mb={1} key={`q-box-alert-${index}`}>
          <QAlert status={'warning'} description={alert} key={`q-alert-${index}`} />
        </QBox>
      ))}
      {testCaseCode && (
        <QFormControl label={'ID'}>
          <QInput
            isDisabled={true}
            {...{ role: 'id-textbox', name: 'idTextBox' }}
            data-cy="id"
            width={150}
            value={testCaseCode}
          />
        </QFormControl>
      )}
      <div style={{ marginBottom: '32px' }}>
        <QFormControl label={'Title'} isInvalid={!!formErrors.title?.message} error={formErrors.title?.message}>
          <Controller name={'title'} as={<QInput data-cy={'title'} />}></Controller>
        </QFormControl>
      </div>
      <QFormControl label={'Test case type'} isInvalid={!!formErrors.type} error={formErrors.type?.type?.message}>
        <Controller
          name="type"
          defaultValue={testCaseSegmentedControlTypes[0]}
          render={({ onChange, value }) => (
            <SegmentedControl
              items={testCaseSegmentedControlTypes}
              onClick={(e) => {
                onChange(e);
                resetFormValuesOnTestCaseTypeChange();
              }}
              active={value}
              isDisabled={isSegmentedControlDisabled}
            />
          )}
        />
      </QFormControl>
      <QFormControl
        label={'Description'}
        isInvalid={!!formErrors.description?.message}
        error={formErrors.description?.message}
      >
        <div style={{ marginBottom: '32px' }}>
          <Controller
            name="description"
            render={({ onChange, value }) => <Editor onChange={onChange} value={value} />}
          />
        </div>
      </QFormControl>
      <div style={{ marginBottom: '32px' }}>
        <QFormControl
          label={'Status'}
          isInvalid={!!formErrors.statusLabel?.label?.message}
          error={formErrors.statusLabel?.label?.message}
        >
          <Controller
            name={'statusLabel'}
            render={({ value, onChange }) => (
              <div data-cy="statusLabel-input">
                <QSelect
                  {...{ id: 'statusLabel' }}
                  options={testCaseStatuses}
                  onChange={onChange}
                  value={value.value}
                  isDisabled={false}
                  isLoading={false}
                  filterOption={null}
                  isSearchable={false}
                />
              </div>
            )}
          />
        </QFormControl>
      </div>

      <HorizontalLine />
      <BasicFormSectionHeader title="Attachments" />
      <QAttachments
        isBusy={setIsBusy}
        attachments={existingAttachments}
        itemInsertLocation={(elA, elB) => elA.filename?.localeCompare(elB.filename)}
        uploadInfoProvider={uploadInfoProvider}
        onAdd={onAttachmentAdd}
        onClick={onAttachmentClick}
        onRemove={onAttachmentRemove}
        onRevert={onAttachmentRemove}
        onWarning={onAttachmentWarning}
        maxFiles={10}
        maxFileSize="100MB"
        maxTotalFileSize="500MB"
      />

      <HorizontalLine />
      <BasicFormSectionHeader title="Connect related design elements" subTitle="Can be done later" />
      {relatedRequirement && (
        <>
          <InputLabel title={`${relatedRequirement.codePrefix?.toUpperCase()} - ${relatedRequirement.label}`} />
          <Controller
            name={relatedRequirement?.type}
            render={({ value, onChange }) => (
              <RelatedElements
                placeHolderText="Search Design Elements"
                handleDeleteChip={(element: SearchElementType) => {
                  const requirementKey = relatedRequirement?.type as RequirementsType;
                  deleteLinkedRequirements(requirementKey, element);
                }}
                watchValue={relatedRequirement?.type}
                value={value}
                onChange={onChange}
                key={relatedRequirement?.label}
              />
            )}
          />
        </>
      )}
    </BasicForm>
  );
};

export default NativeTestCasePresentational;
