import React, { useContext } from 'react';

import { QualioQThemeProvider, QualioThemeProvider, ToastProvider } from '@qualio/ui-components';
import { ConfigProvider } from 'antd';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import { UserGroupToggle, UserPermissions } from './components';
import { FeatureFlags } from './components/FeatureToggle/FeatureToggle.enum';
import useFeatureFlag from './components/FeatureToggle/hooks/useFeatureFlag';
import { ChangeControlDrawerContainer } from './components_new/ChangeControlDetailsDrawer/ChangeControlDrawerContainer';
import { DesignElementDrawerContainer } from './components_new/DesginElementDetailsDrawer/DesignElementDrawerContainer';
import { QViewsStylingFix } from './components_new/QViewsStylingFix/QViewsStylingFix';
import { ContainerContext } from './context/container';
import { RiskSubType } from './types/risk';
import Configuration, { IndexRouter } from './views/Configuration';
import RequirementConfigurationView from './views/Configuration/Requirements';
import RiskConfigurationView from './views/Configuration/Risk';
import TestCaseConfigurationView from './views/Configuration/TestCases';
import Dashboard from './views/Dashboard';
import Documents from './views/Documents/Documents';
import EditProduct from './views/EditProductV2/EditProduct';
import CreateNativeTestCase from './views/NativeTestCase/components/CreateNativeTestCase';
import EditNativeTestCase from './views/NativeTestCase/components/EditNativeTestCase';
import OpenIssues from './views/OpenIssues';
import { ProductView } from './views/Product/Product';
import CreateProductRequirement from './views/ProductRequirement/CreateProductRequirement';
import UpdateProductRequirement from './views/ProductRequirement/UpdateProductRequirement';
import Requirement from './views/Requirements/Requirement';
import { SelfServiceDocuments } from './views/SelfServiceDocuments/SelfServiceDocuments';
import { TestCaseResults } from './views/TestCaseResult/components/TestCaseResultsContainer';
import { CreateProduct } from './views_new/CreateProduct/CreateProduct';
import { CreateRisk as CreateRiskForm } from './views_new/CreateRisk/CreateRisk';
import { EditRisk } from './views_new/EditRisk/EditRisk';
import ProductList from './views_new/ProductList';
import { CreateReviewForm } from './views_new/Review/Form/createReview';
import { EditReviewForm } from './views_new/Review/Form/editReview';
import { ReviewListContainer } from './views_new/Review/List';
import { RiskListContainer } from './views_new/RiskList/RiskList';

const App: React.FC = () => {
  const { element } = useContext(ContainerContext);
  const features = useFeatureFlag([FeatureFlags.SELF_SERVICE_DOCUMENTS]);

  return (
    <ConfigProvider prefixCls="pd-ant" getPopupContainer={() => element}>
      <QualioThemeProvider>
        <ToastProvider>
          <Router basename={'/design-controls/'}>
            <Routes>
              <Route
                path={`product/create/:step?`}
                element={
                  <UserGroupToggle
                    userPermission={UserPermissions.MANAGE_PRODUCT}
                    showInsufficientUserPermissionEmptyState={true}
                  >
                    <QViewsStylingFix>
                      <QualioQThemeProvider>
                        <CreateProduct />
                      </QualioQThemeProvider>
                    </QViewsStylingFix>
                  </UserGroupToggle>
                }
              />

              <Route
                path={`product/edit/:product`}
                element={
                  <UserGroupToggle
                    userPermission={UserPermissions.MANAGE_PRODUCT}
                    showInsufficientUserPermissionEmptyState={true}
                  >
                    <QualioQThemeProvider>
                      <EditProduct />
                    </QualioQThemeProvider>
                  </UserGroupToggle>
                }
              />

              <Route path="product/:product" element={<ProductView />}>
                <Route
                  path={`requirement/add`}
                  element={
                    <QViewsStylingFix>
                      <QualioQThemeProvider>
                        <UserGroupToggle
                          userPermission={UserPermissions.CREATE_DESIGN_ELEMENTS}
                          showInsufficientUserPermissionEmptyState={true}
                        >
                          <CreateProductRequirement />
                        </UserGroupToggle>
                      </QualioQThemeProvider>
                    </QViewsStylingFix>
                  }
                />
                <Route
                  path={`requirement/edit/:requirementId`}
                  element={
                    <QViewsStylingFix>
                      <QualioQThemeProvider>
                        <UserGroupToggle
                          userPermission={UserPermissions.EDIT_DESIGN_ELEMENTS}
                          showInsufficientUserPermissionEmptyState={true}
                        >
                          <UpdateProductRequirement />
                        </UserGroupToggle>
                      </QualioQThemeProvider>
                    </QViewsStylingFix>
                  }
                />
                <Route
                  path={`requirement`}
                  element={
                    <QViewsStylingFix>
                      <QualioQThemeProvider>
                        <Requirement />
                      </QualioQThemeProvider>
                    </QViewsStylingFix>
                  }
                >
                  <Route path=":selectedItemId" element={<DesignElementDrawerContainer />} />
                </Route>

                <Route
                  path={`testCase/add`}
                  element={
                    <QualioQThemeProvider>
                      <ToastProvider>
                        <UserGroupToggle
                          userPermission={UserPermissions.CREATE_DESIGN_ELEMENTS}
                          showInsufficientUserPermissionEmptyState={true}
                        >
                          <CreateNativeTestCase />
                        </UserGroupToggle>
                      </ToastProvider>
                    </QualioQThemeProvider>
                  }
                />
                <Route
                  path={`testCase/edit/:testCaseId`}
                  element={
                    <QualioQThemeProvider>
                      <ToastProvider>
                        <UserGroupToggle
                          userPermission={UserPermissions.EDIT_DESIGN_ELEMENTS}
                          showInsufficientUserPermissionEmptyState={true}
                        >
                          <EditNativeTestCase />
                        </UserGroupToggle>
                      </ToastProvider>
                    </QualioQThemeProvider>
                  }
                />

                <Route
                  path={`testCase/results`}
                  element={
                    <UserGroupToggle
                      userPermission={UserPermissions.CREATE_DESIGN_ELEMENTS}
                      showInsufficientUserPermissionEmptyState={true}
                    >
                      <QViewsStylingFix>
                        <QualioQThemeProvider>
                          <ToastProvider>
                            <TestCaseResults />
                          </ToastProvider>
                        </QualioQThemeProvider>
                      </QViewsStylingFix>
                    </UserGroupToggle>
                  }
                >
                  <Route path={':selectedItemId'} element={<DesignElementDrawerContainer />} />
                </Route>

                <Route
                  path={`risk/add/fmea`}
                  element={
                    <UserGroupToggle
                      userPermission={UserPermissions.CREATE_DESIGN_ELEMENTS}
                      showInsufficientUserPermissionEmptyState={true}
                    >
                      <QViewsStylingFix>
                        <QualioQThemeProvider>
                          <ToastProvider>
                            <CreateRiskForm subType={'FMEA'} />
                          </ToastProvider>
                        </QualioQThemeProvider>
                      </QViewsStylingFix>
                    </UserGroupToggle>
                  }
                />

                <Route
                  path={`risk/add`}
                  element={
                    <UserGroupToggle
                      userPermission={UserPermissions.CREATE_DESIGN_ELEMENTS}
                      showInsufficientUserPermissionEmptyState={true}
                      showIsLoading={true}
                    >
                      <QViewsStylingFix>
                        <QualioQThemeProvider>
                          <ToastProvider>
                            <CreateRiskForm subType={'ISO'} />
                          </ToastProvider>
                        </QualioQThemeProvider>
                      </QViewsStylingFix>
                    </UserGroupToggle>
                  }
                />
                <Route
                  path={`risk/edit/:itemId`}
                  element={
                    <UserGroupToggle
                      userPermission={UserPermissions.EDIT_DESIGN_ELEMENTS}
                      showInsufficientUserPermissionEmptyState={true}
                      showIsLoading={true}
                    >
                      <QViewsStylingFix>
                        <QualioQThemeProvider>
                          <ToastProvider>
                            <EditRisk />
                          </ToastProvider>
                        </QualioQThemeProvider>
                      </QViewsStylingFix>
                    </UserGroupToggle>
                  }
                />

                <Route
                  path={`risk/fmea`}
                  element={
                    <QViewsStylingFix>
                      <QualioQThemeProvider>
                        <ToastProvider>
                          <RiskListContainer subType={RiskSubType.FMEA} />
                        </ToastProvider>
                      </QualioQThemeProvider>
                    </QViewsStylingFix>
                  }
                >
                  <Route path={':selectedItemId'} element={<DesignElementDrawerContainer />} />
                  <Route path={':selectedRiskId/:selectedItemId'} element={<DesignElementDrawerContainer />} />
                </Route>
                <Route
                  path={`risk/iso`}
                  element={
                    <QViewsStylingFix>
                      <QualioQThemeProvider>
                        <ToastProvider>
                          <RiskListContainer subType={RiskSubType.ISO} />
                        </ToastProvider>
                      </QualioQThemeProvider>
                    </QViewsStylingFix>
                  }
                >
                  <Route path={':selectedItemId'} element={<DesignElementDrawerContainer />} />
                  <Route path={':selectedRiskId/:selectedItemId'} element={<DesignElementDrawerContainer />} />
                </Route>

                <Route path={`risk`} element={<Navigate to={`iso`} />} />

                <Route
                  path="document"
                  element={features[FeatureFlags.SELF_SERVICE_DOCUMENTS] ? <SelfServiceDocuments /> : <Documents />}
                />
                <Route index element={<Navigate to={`requirement`} />} />
                <Route path={`dashboard`} element={<Dashboard />}>
                  <Route path={`:selectedItemId`} element={<DesignElementDrawerContainer />} />
                </Route>
                <Route
                  path={`create-review`}
                  element={
                    <UserGroupToggle
                      userPermission={UserPermissions.CREATE_CHANGE_CONTROL}
                      showInsufficientUserPermissionEmptyState={true}
                      showIsLoading={true}
                    >
                      <QViewsStylingFix>
                        <QualioQThemeProvider>
                          <ToastProvider>
                            <CreateReviewForm />
                          </ToastProvider>
                        </QualioQThemeProvider>
                      </QViewsStylingFix>
                    </UserGroupToggle>
                  }
                >
                  <Route path={':selectedItemId'} element={<DesignElementDrawerContainer />} />
                </Route>
                <Route
                  path={`review/:changeControlId`}
                  element={
                    <UserGroupToggle
                      userPermission={UserPermissions.EDIT_CHANGE_CONTROL}
                      showInsufficientUserPermissionEmptyState={true}
                      showIsLoading={true}
                    >
                      <QViewsStylingFix>
                        <QualioQThemeProvider>
                          <ToastProvider>
                            <EditReviewForm />
                          </ToastProvider>
                        </QualioQThemeProvider>
                      </QViewsStylingFix>
                    </UserGroupToggle>
                  }
                >
                  <Route path={':selectedItemId'} element={<DesignElementDrawerContainer />} />
                </Route>
                <Route
                  path={`change-control`}
                  element={
                    <QViewsStylingFix>
                      <QualioQThemeProvider>
                        <ToastProvider>
                          <ReviewListContainer />
                        </ToastProvider>
                      </QualioQThemeProvider>
                    </QViewsStylingFix>
                  }
                >
                  <Route path={':changeControlId'} element={<ChangeControlDrawerContainer />} />
                  <Route path={':changeControlId/:selectedItemId'} element={<DesignElementDrawerContainer />} />
                </Route>
                <Route path={`open-issues`} element={<OpenIssues />}>
                  <Route path={':selectedItemId'} element={<DesignElementDrawerContainer />} />
                </Route>
                <Route
                  path={`config`}
                  element={
                    <UserGroupToggle
                      userPermission={UserPermissions.EDIT_DESIGN_ELEMENT_POLICIES}
                      showInsufficientUserPermissionEmptyState={true}
                    >
                      <QViewsStylingFix>
                        <QualioQThemeProvider>
                          <Configuration />
                        </QualioQThemeProvider>
                      </QViewsStylingFix>
                    </UserGroupToggle>
                  }
                >
                  <Route path={`requirement`} element={<RequirementConfigurationView />} />
                  <Route path={`testCases`} element={<TestCaseConfigurationView />} />
                  <Route path={`risk`} element={<RiskConfigurationView />} />
                  <Route index element={<IndexRouter />} />
                </Route>
              </Route>

              <Route
                path="product"
                element={
                  <QViewsStylingFix>
                    <QualioQThemeProvider>
                      <ToastProvider>
                        <ProductList />
                      </ToastProvider>
                    </QualioQThemeProvider>
                  </QViewsStylingFix>
                }
              />

              <Route index element={<Navigate to="/product" />} />
            </Routes>
          </Router>
        </ToastProvider>
      </QualioThemeProvider>
    </ConfigProvider>
  );
};

export default App;
