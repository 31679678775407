import React, { useCallback, useMemo, useState } from 'react';

import { QBadge, deprecated, QMenuButton, QMenuItem, QTag } from '@qualio/ui-components';
import { useNavigate } from 'react-router-dom';

import { UserPermissions } from '../../components';
import { DeleteConfirmationModal } from '../../components/DeleteDesignElementModal/DeleteConfirmationModal';
import { calculateFmeaRisk } from '../../components/RiskForm/formHelpers';
import useHasPermission from '../../components/UserGroupToggle/hooks/useHasPermission';
import { useCustomSorting } from '../../hooks/useCustomSorting';
import { sortByCode } from '../../lib/sortByCode';
import { sortByAlphanumericInsensitive, sortByDate } from '../../lib/sortFunctions';
import { DesignElementType, QualityItemStatus } from '../../types/baseQualityItem';
import { RiskQualityTypeConfig } from '../../types/qualityTypeConfig';
import { FMEARisk, RiskSubType } from '../../types/risk';
import { fmeaRiskScoreColor } from '../../views_new/Review/List/statusLabelAndColorTag';
import { ClickPropagationBarrier } from '../ClickPropogateBarrier/ClickPropogateBarrier';
import { RiskEmptyState } from '../EmptyState/Risk';
import { PageTable } from '../PageTable/PageTable';
import { QTextWithHover } from '../ShortenTextAndHover/QTextWithHover';
import { DesignControlTag } from '../Tag/DesignControlTag';
import { getDesignControlStatus } from './lib/getDesignControlStatus';
import { riskTableDefaultSortBy } from './lib/riskTableDefaultSortBy';

export type FMEARiskTableProps = {
  productId: string;
  onRowClick: (row: deprecated.QDataRow) => void;
  selectedItemId?: string;
  isLoading: boolean;
  designElements: Array<FMEARisk>;
  deleteDesignElement: (designElementId: string, type: DesignElementType) => void;
  riskConfig: RiskQualityTypeConfig | undefined;
};

export const FMEARiskTable: React.FC<FMEARiskTableProps> = ({
  productId,
  onRowClick,
  selectedItemId,
  isLoading,
  designElements,
  deleteDesignElement,
  riskConfig,
}) => {
  const [elementToBeDeleted, setElementToBeDeleted] = useState<FMEARisk | undefined>(undefined);
  const permissions = useHasPermission([UserPermissions.EDIT_DESIGN_ELEMENTS, UserPermissions.DELETE_DESIGN_ELEMENTS]);

  const navigate = useNavigate();

  const onEditClick = useCallback(
    (designElementId: string) => {
      navigate(`/product/${productId}/risk/edit/${designElementId}`);
    },
    [navigate, productId],
  );

  const deleteElementCallback = useCallback(() => {
    if (elementToBeDeleted) {
      deleteDesignElement(elementToBeDeleted.id, elementToBeDeleted.type);
    }

    setElementToBeDeleted(undefined);
  }, [deleteDesignElement, elementToBeDeleted, setElementToBeDeleted]);
  const closeDeleteElementCallback = useCallback(() => {
    setElementToBeDeleted(undefined);
  }, [setElementToBeDeleted]);

  const { sortedData, handleSortByCallback } = useCustomSorting<FMEARisk>(
    { id: 'code', desc: false },
    {
      code: sortByCode,
      failureModeEffects: (firstFMEARisk, secondFMEARisk) => {
        return sortByAlphanumericInsensitive(firstFMEARisk.failureModeEffects, secondFMEARisk.failureModeEffects);
      },
      failureMode: (firstFMEARisk, secondFMEARisk) => {
        return sortByAlphanumericInsensitive(firstFMEARisk.failureMode, secondFMEARisk.failureMode);
      },
      failureCauses: (firstFMEARisk, secondFMEARisk) => {
        return sortByAlphanumericInsensitive(firstFMEARisk.failureCauses, secondFMEARisk.failureCauses);
      },
      initialAssessment: (firstFMEARisk, secondFMEARisk) => {
        return calculateFmeaRisk(firstFMEARisk.preMitigation) - calculateFmeaRisk(secondFMEARisk.preMitigation);
      },
      finalAssessment: (firstFMEARisk, secondFMEARisk) => {
        return calculateFmeaRisk(firstFMEARisk.postMitigation) - calculateFmeaRisk(secondFMEARisk.postMitigation);
      },
      updated: (firstFMEARisk, secondFMEARisk) => {
        return sortByDate(new Date(firstFMEARisk.updated), new Date(secondFMEARisk.updated));
      },
      changeControlStatus: (firstFMEARisk, secondFMEARisk) => {
        const firstISORiskStatus = getDesignControlStatus(firstFMEARisk);
        const secondISORiskStatus = getDesignControlStatus(secondFMEARisk);
        return sortByAlphanumericInsensitive(firstISORiskStatus, secondISORiskStatus);
      },
    },
    designElements ?? [],
  );

  const columns: Array<deprecated.QDataColumn> = [
    {
      Header: 'ID',
      accessor: 'code',
      width: '10%',
    },
    {
      Header: 'Failure Mode',
      accessor: 'failureMode',
    },
    {
      Header: 'Effects of Failure Mode',
      accessor: 'failureModeEffects',
    },
    {
      Header: 'Causes',
      accessor: 'failureCauses',
    },
    {
      Header: 'Initial',
      accessor: 'initialAssessment',
    },
    {
      Header: 'Final',
      accessor: 'finalAssessment',
    },
    {
      Header: 'Last Modified',
      accessor: 'updated',
      type: deprecated.QColumnType.DATE,
    },
    {
      Header: 'Design Control',
      accessor: 'changeControlStatus',
    },
    {
      Header: '',
      accessor: 'menu',
      disableSortBy: true,
    },
  ];

  const rows: Array<deprecated.QDataRow> = useMemo(() => {
    return sortedData.map((item) => ({
      ...item,
      code: <QBadge>{item.code}</QBadge>,
      failureMode: <QTextWithHover value={item.failureMode} />,
      failureModeEffects: <QTextWithHover value={item.failureModeEffects} />,
      failureCauses: <QTextWithHover value={item.failureCauses} />,
      changeControlStatus: (
        <DesignControlTag
          changeControlStatus={item.changeControlStatus}
          itemStatus={item.itemStatus as QualityItemStatus}
        />
      ),
      initialAssessment: (
        <QTag
          data-cy={'initial-risk-score'}
          variantColor={fmeaRiskScoreColor(
            calculateFmeaRisk(item.preMitigation),
            riskConfig?.assessment.fmea?.mitigationThreshold,
          )}
        >
          {calculateFmeaRisk(item.preMitigation)}
        </QTag>
      ),
      finalAssessment: item.postMitigation ? (
        <QTag
          data-cy={'final-risk-score'}
          variantColor={fmeaRiskScoreColor(
            calculateFmeaRisk(item.postMitigation),
            riskConfig?.assessment.fmea?.mitigationThreshold,
          )}
        >
          {calculateFmeaRisk(item.postMitigation)}
        </QTag>
      ) : null,
      menu: permissions[UserPermissions.EDIT_DESIGN_ELEMENTS] &&
        permissions[UserPermissions.DELETE_DESIGN_ELEMENTS] && (
          <ClickPropagationBarrier data-cy={`action-menu`}>
            <QMenuButton variant="icon" buttonLabel="options" iconName="MoreVertical">
              <QMenuItem onClick={() => onEditClick(item.id)} data-cy={`edit-${item.id}`}>
                Edit
              </QMenuItem>
              <QMenuItem color="red.500" onClick={() => setElementToBeDeleted(item)} data-cy={`delete-${item.id}`}>
                Delete
              </QMenuItem>
            </QMenuButton>
          </ClickPropagationBarrier>
        ),
    })) as unknown as Array<deprecated.QDataRow>;
  }, [sortedData, onEditClick, permissions, riskConfig]);

  return (
    <>
      <div data-cy={'risk-fmea-table'}>
        <PageTable
          columns={columns}
          data={rows}
          isLoading={isLoading}
          pageSize={25}
          emptyStateComponent={<RiskEmptyState productId={productId} riskSubType={RiskSubType.FMEA} />}
          onRowClick={onRowClick}
          defaultSelectedRow={selectedItemId}
          manualSortBy={{ sortByCallback: handleSortByCallback, ...riskTableDefaultSortBy }}
        />
      </div>
      <DeleteConfirmationModal
        onConfirm={deleteElementCallback}
        onClose={closeDeleteElementCallback}
        confirmationDetails={
          elementToBeDeleted
            ? {
                title: 'Delete Risk',
                message: (
                  <>
                    Are you sure you want to delete <b>{elementToBeDeleted.code}</b>?
                  </>
                ),
              }
            : undefined
        }
      />
    </>
  );
};
