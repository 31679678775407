import React, { useMemo } from 'react';

import { QFormControl, QInput, QMultiSelect, QStack, QText } from '@qualio/ui-components';
import { Controller, useFormContext } from 'react-hook-form';

import { RadioGroup } from '../../components_new/RadioGroup/RadioGroup';
import { useTags } from '../../hooks/documents/useTags';
import { productType } from '../../types/product';
import { FeatureFlags } from '../FeatureToggle/FeatureToggle.enum';
import useFeatureFlag from '../FeatureToggle/hooks/useFeatureFlag';

const ProductInfoForm: React.FC = () => {
  const {
    formState: { errors },
  } = useFormContext();

  const features = useFeatureFlag([FeatureFlags.SELF_SERVICE_DOCUMENTS]);

  const { data: tags, isLoading: isLoadingTags } = useTags();

  const tagOptions = useMemo(() => {
    return (tags ?? []).map((item) => ({
      value: item.id,
      label: item.name,
    }));
  }, [tags]);

  return (
    <div data-cy="product-info-form">
      <QFormControl label="Product name *" isInvalid={!!errors.name} error={errors.name?.message}>
        <Controller
          name="name"
          render={({ onChange, value }) => <QInput value={value} onChange={onChange} data-cy={`product-name`} />}
        />
      </QFormControl>

      <QStack mb={5} mt={5} gap={2}>
        <QFormControl label={'Type'} isInvalid={!!errors.type} error={errors.type?.message}>
          <Controller
            name="type"
            render={({ value, onChange, name }) => (
              <RadioGroup
                defaultValue={value}
                name={name}
                onChange={onChange}
                options={productType.options.map((option) => ({ label: option.value, value: option.value }))}
              />
            )}
          />
        </QFormControl>
        {features[FeatureFlags.SELF_SERVICE_DOCUMENTS] && (
          <QFormControl label="Document tags" isInvalid={!!errors.tags} data-cy="document-tags-multiselect">
            <QStack mb={2}>
              <QText fontSize="sm" color="gray.900">
                Documents must contain all of your selected tags to appear in the documents tab.
              </QText>
            </QStack>
            <Controller
              name="tags"
              render={({ value, onChange }) => (
                <QMultiSelect
                  options={tagOptions}
                  isLoading={isLoadingTags}
                  value={value}
                  onChange={(values) => onChange(values.map((item) => item.value))}
                />
              )}
            ></Controller>
          </QFormControl>
        )}
      </QStack>
    </div>
  );
};

export default ProductInfoForm;
