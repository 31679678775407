import React, { useCallback } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { QBox, QButton, QDivider, QFormControl, QInput, QStack, QText, QTextarea } from '@qualio/ui-components';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { FeatureFlags } from '../../components/FeatureToggle/FeatureToggle.enum';
import useFeatureFlag from '../../components/FeatureToggle/hooks/useFeatureFlag';
import { DesignElementResourceItemFormField } from '../../components/QLookup';
import { useIsoRiskCalculateScore } from '../../hooks/risk/useIsoRiskCalculateScore';
import { handleFormErrors } from '../../lib/formValidationUtilities';
import { DesignElementType } from '../../types/baseQualityItem';
import { ISORiskRequest, ISORiskRequestType, RiskSubType } from '../../types/risk';
import { ISORiskRadio } from '../ISORiskRadio/ISORiskRadio';
import { RiskTag } from '../Tag/RiskTag';
import { RiskSourceMitigationItemSelect } from './components/RiskSourceMitigationItemSelect';
import { RiskStatusLabelSelect } from './components/RiskStatusLabelSelect';
import { RiskFormProps } from './type';

const defaultValue: ISORiskRequestType = {
  title: '',
  statusLabel: '',
  category: undefined,
  foreseeableUseMisuse: '',
  hazard: '',
  hazardSituation: '',
  harm: '',
  riskControl: { mitigation: '' },
  preMitigation: { probability: '', severity: '' },
  postMitigation: { probability: '', severity: '' },
  mitigationItems: [],
  sourceItems: [],
};

type ISOFormProps = RiskFormProps<ISORiskRequestType>;

export const ISOForm: React.FC<ISOFormProps> = ({
  isSubmitting,
  configs,
  onClose,
  onSubmit,
  riskConfig,
  initialValue = defaultValue,
  autoCompleteOptions,
  itemId,
}) => {
  const features = useFeatureFlag([FeatureFlags.DESIGN_CONTROLS_CATEGORIES]);
  const resourceIdentifier = riskConfig.category?.[0].resourceIdentifier;

  const methods = useForm<ISORiskRequestType>({
    mode: 'onChange',
    defaultValues: initialValue,
    reValidateMode: 'onChange',
    resolver: zodResolver(ISORiskRequest),
  });
  const {
    formState: { errors },
    setError,
    watch,
  } = methods;

  const preMitigationWatchFields = watch(['preMitigation.probability', 'preMitigation.severity']) as Record<
    string,
    string
  >;
  const postMitigationWatchFields = watch(['postMitigation.probability', 'postMitigation.severity']) as Record<
    string,
    string
  >;

  const preMitigationScore = useIsoRiskCalculateScore(riskConfig, 'preMitigation', preMitigationWatchFields);
  const postMitigationScore = useIsoRiskCalculateScore(riskConfig, 'postMitigation', postMitigationWatchFields);
  const showMitigationFields =
    preMitigationScore && !riskConfig.assessment.iso.acceptableLevels.find((item) => item === preMitigationScore);

  const handleSubmitClick = useCallback(
    (payload: ISORiskRequestType) => {
      const preMitigationPayload = {
        ...payload.preMitigation,
        riskLevel: preMitigationScore,
      };

      let postMitigationPayload = undefined;
      let riskControl = undefined;

      if (showMitigationFields) {
        riskControl = payload.riskControl;
        postMitigationPayload = payload.postMitigation
          ? {
              ...payload.postMitigation,
              riskLevel: postMitigationScore,
            }
          : undefined;
      }

      const sourceItemIds = payload.sourceItems?.map((item) => item.id) ?? [];
      const mitigationItemIds = payload.mitigationItems?.map((item) => item.id) ?? [];

      onSubmit(
        {
          ...payload,
          riskControl,
          id: itemId,
          subType: RiskSubType.ISO,
          type: DesignElementType.RISK,
          preMitigation: preMitigationPayload,
          postMitigation: postMitigationPayload,
          sourceItems: sourceItemIds,
          mitigationItems: mitigationItemIds,
        } as any,
        {
          onSuccess: () => undefined,
          onError: handleFormErrors(setError),
        },
      );
    },
    [onSubmit, preMitigationScore, postMitigationScore, showMitigationFields, itemId, setError],
  );

  return (
    <QBox margin="auto" data-cy="iso-risk-form">
      <FormProvider {...methods}>
        <QFormControl
          label="Title"
          isInvalid={!!errors.title}
          error={errors.title?.message}
          helper="&nbsp;"
          id={'title'}
        >
          <Controller
            name={'title'}
            render={({ onChange, value }) => <QInput onChange={onChange} value={value} data-cy="title-input" />}
          />
        </QFormControl>
        {features[FeatureFlags.DESIGN_CONTROLS_CATEGORIES] && resourceIdentifier && (
          <div style={{ marginBottom: '32px' }}>
            <DesignElementResourceItemFormField
              name="category"
              label="Category"
              resourceSubType={resourceIdentifier}
              title="Select category"
              action="Add category"
            />
          </div>
        )}
        <QFormControl
          label="Status"
          isInvalid={!!errors.statusLabel}
          error={errors.statusLabel?.message}
          helper="&nbsp;"
          id={'statusLabel'}
        >
          <Controller
            name={'statusLabel'}
            render={({ onChange, value }) => (
              <RiskStatusLabelSelect value={value} options={riskConfig.workflow.states} onChange={onChange} />
            )}
          />
        </QFormControl>
        <QDivider mb="30px" />
        <QBox mb={2}>
          <QText fontSize="md" weight={'semibold'}>
            Initial Assessment
          </QText>
        </QBox>
        <QFormControl
          label="Hazard"
          isInvalid={!!errors.hazard}
          error={errors.hazard?.message}
          helper="&nbsp;"
          id={'hazard'}
        >
          <Controller
            name={'hazard'}
            render={({ onChange, value }) => <QInput onChange={onChange} value={value} data-cy="hazard-input" />}
          />
        </QFormControl>
        <QFormControl
          label="Reasonably Forseeable Use / Misuse"
          isInvalid={!!errors.foreseeableUseMisuse}
          error={errors.foreseeableUseMisuse?.message}
          helper="&nbsp;"
          id={'foreseeableUseMisuse'}
        >
          <Controller
            name={'foreseeableUseMisuse'}
            render={({ onChange, value }) => (
              <QTextarea onChange={onChange} value={value} data-cy="foreseeableUseMisuse-input" />
            )}
          />
        </QFormControl>
        <QFormControl
          label="Hazardous Situation"
          isInvalid={!!errors.hazardSituation}
          error={errors.hazardSituation?.message}
          helper="&nbsp;"
          id={'hazardSituation'}
        >
          <Controller
            name={'hazardSituation'}
            render={({ onChange, value }) => (
              <QTextarea onChange={onChange} value={value} data-cy="hazardSituation-input" />
            )}
          />
        </QFormControl>
        <QFormControl
          label="Probability"
          isInvalid={!!errors.preMitigation?.probability}
          error={errors.preMitigation?.probability?.message}
          helper="&nbsp;"
          id={'preMitigation.probability'}
        >
          <Controller
            name={'preMitigation.probability'}
            render={({ onChange, value }) => (
              <ISORiskRadio
                onChange={onChange}
                value={value}
                riskLevels={riskConfig.assessment.iso.probability}
                radioGroupName={'preMitigation.probability'}
              />
            )}
          />
        </QFormControl>
        <QFormControl label="Harm" isInvalid={!!errors.harm} error={errors.harm?.message} helper="&nbsp;" id={'harm'}>
          <Controller
            name={'harm'}
            render={({ onChange, value }) => <QTextarea onChange={onChange} value={value} data-cy="harm-input" />}
          />
        </QFormControl>
        <QFormControl
          label="Severity"
          isInvalid={!!errors.preMitigation?.severity}
          error={errors.preMitigation?.severity?.message}
          helper="&nbsp;"
          id={'preMitigation.severity'}
        >
          <Controller
            name={'preMitigation.severity'}
            render={({ onChange, value }) => (
              <ISORiskRadio
                onChange={onChange}
                value={value}
                riskLevels={riskConfig.assessment.iso.severity}
                radioGroupName={'preMitigation.severity'}
              />
            )}
          />
        </QFormControl>
        {preMitigationScore && (
          <QBox mb="30px">
            <QBox mb="5px">
              <QText weight={'semibold'} fontSize="md" data-cy={'pre-mitigation-risk-score'}>
                Risk Level
              </QText>
            </QBox>
            <RiskTag label={preMitigationScore} riskLevels={riskConfig.assessment.iso.levels} />
          </QBox>
        )}
        <QStack spacing="30px" mb="30px">
          <Controller
            name={'sourceItems'}
            defaultValue={initialValue.sourceItems ?? []}
            render={({ onChange, value }) => (
              <RiskSourceMitigationItemSelect
                title={'Impacted Design Elements'}
                titleVariant={true}
                name={'sourceItems'}
                placeholder={'Search design elements to add as source'}
                options={autoCompleteOptions}
                values={value}
                onChange={onChange}
                configs={configs}
              />
            )}
          />
        </QStack>
        <QDivider mt="30px" mb="30px" />
        {showMitigationFields && (
          <>
            <QBox mb={2}>
              <QText weight={'semibold'} fontSize="md">
                Mitigation
              </QText>
            </QBox>
            <QFormControl
              label="Description"
              isInvalid={!!errors.riskControl?.mitigation}
              error={errors.riskControl?.mitigation?.message}
              helper="&nbsp;"
              id={'riskControl.mitigation'}
            >
              <Controller
                name={'riskControl.mitigation'}
                render={({ onChange, value }) => (
                  <QTextarea onChange={onChange} value={value} data-cy="riskControl.mitigation-input" />
                )}
              />
            </QFormControl>
            <QStack spacing="30px" mb="30px">
              <Controller
                name={'mitigationItems'}
                defaultValue={initialValue.mitigationItems ?? []}
                render={({ onChange, value }) => (
                  <RiskSourceMitigationItemSelect
                    title={'Design Elements Associated With Mitigation'}
                    titleVariant={true}
                    name={'mitigationItems'}
                    placeholder={'Search design elements to add as mitigation'}
                    options={autoCompleteOptions}
                    values={value}
                    onChange={onChange}
                    configs={configs}
                  />
                )}
              />
            </QStack>
            <QDivider mt="30px" mb="30px" />
            <QBox mb={2}>
              <QText weight={'semibold'} fontSize="md">
                Final Assessment
              </QText>
            </QBox>
            <QFormControl
              label="Probability"
              isInvalid={!!errors.postMitigation?.probability}
              error={errors.postMitigation?.probability?.message}
              helper="&nbsp;"
              id={'postMitigation.probability'}
            >
              <Controller
                name={'postMitigation.probability'}
                render={({ onChange, value }) => (
                  <ISORiskRadio
                    onChange={onChange}
                    value={value}
                    riskLevels={riskConfig.assessment.iso.probability}
                    radioGroupName={'postMitigation.probability'}
                  />
                )}
              />
            </QFormControl>
            <QFormControl
              label="Severity"
              isInvalid={!!errors.postMitigation?.severity}
              error={errors.postMitigation?.severity?.message}
              helper="&nbsp;"
              id={'postMitigation.severity'}
            >
              <Controller
                name={'postMitigation.severity'}
                render={({ onChange, value }) => (
                  <ISORiskRadio
                    onChange={onChange}
                    value={value}
                    riskLevels={riskConfig.assessment.iso.severity}
                    radioGroupName={'postMitigation.severity'}
                  />
                )}
              />
            </QFormControl>
            {postMitigationScore && (
              <QBox mb="30px">
                <QBox mb="5px">
                  <QText weight={'semibold'} fontSize="md" data-cy={'post-mitigation-risk-score'}>
                    Risk Level
                  </QText>
                </QBox>
                <RiskTag label={postMitigationScore} riskLevels={riskConfig.assessment.iso.levels} />
              </QBox>
            )}
            <QDivider mb="30px" />
          </>
        )}
        <QStack spacing="20px" direction="row" justifyContent="flex-end">
          <QButton variant={'link'} onClick={onClose} data-cy="cancel-button" isDisabled={isSubmitting}>
            Cancel
          </QButton>
          <QButton
            variant={'solid'}
            data-cy="submit-button"
            onClick={methods.handleSubmit(handleSubmitClick)}
            isLoading={isSubmitting}
          >
            Save
          </QButton>
        </QStack>
      </FormProvider>
    </QBox>
  );
};
