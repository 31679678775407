import { useMemo } from 'react';

export const useFmeaRiskCalculateScore = (
  mitigationLevel: 'preMitigation' | 'postMitigation',
  watchValue: Record<string, number>,
): number | undefined => {
  return useMemo(() => {
    const severityValue = watchValue[`${mitigationLevel}.severity`];
    const occurrenceValue = watchValue[`${mitigationLevel}.occurrence`];
    const detectabilityValue = watchValue[`${mitigationLevel}.detectability`];

    if (!severityValue || !occurrenceValue || !detectabilityValue) {
      return undefined;
    }

    return severityValue * occurrenceValue * detectabilityValue;
  }, [watchValue, mitigationLevel]);
};
