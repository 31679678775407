import React from 'react';

import { QFormControl, QInput } from '@qualio/ui-components';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

type MatrixLabelsProps = {
  name: string;
};

const MatrixLabels: React.FC<MatrixLabelsProps> = ({ name }) => {
  const { control, errors } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name,
  });

  return (
    <>
      {fields.map((field, index) => (
        <div className="item" key={`${name}_${field.id}`}>
          <QFormControl label={''} isInvalid={!!errors?.[name]?.[index]}>
            <Controller
              data-cy={`matrix-label-${name}`}
              name={`${name}[${index}].label`}
              as={<QInput />}
              defaultValue={field.label}
            />
          </QFormControl>
        </div>
      ))}
    </>
  );
};

export default MatrixLabels;
