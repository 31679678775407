import { Payload } from '../../types/payload';
import { Product } from '../../types/product';
import { makeResponse } from './generic';

export const productsResponse: Payload<Product[]> = makeResponse([
  {
    name: 'Test product',
    id: 'test-1',
    type: 'Physical device only',
  },
  {
    name: 'Second product',
    id: 'test-2',
  },
]);
