import React, { useMemo, useState } from 'react';

import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import itemsApi from '../../../api/items';
import { Loading } from '../../../components_new/Loading';
import { useCompanyId } from '../../../context/CurrentUserContext';
import { useProduct } from '../../../context/product';
import { useQualityConfigs } from '../../../context/qualityConfigs';
import { useRouteParams } from '../../../hooks/useRouteParams';
import { retrieveNativeTestCases } from '../../../lib/designControlNativeEnabled';
import { filterValidationErrors, scrollWindowToTop } from '../../../lib/formValidationUtilities';
import { TestCaseParameters } from '../../../paramTypes';
import { RequestValidationError } from '../../../types/requestValidationError';
import { FormRelatedElementType, ProductRequirementFormRelatedElementsType } from '../../../types/requirement';
import { TestCaseTraceWithResult } from '../../../types/trace';
import { NativeTestCaseContainerFormType, TestCasePayload } from '../assets/NativeTestCase.zod';
import { toFormRelatedElementType, toTestCaseSegmentedControlType } from '../utils/NativeTestCase.utils';
import NativeTestCaseContainer from './NativeTestCaseContainer';

const EditNativeTestCase: React.FC = () => {
  const product = useProduct();
  const navigate = useNavigate();
  const companyId = useCompanyId();
  const { configs, loading } = useQualityConfigs();
  const { testCaseId } = useRouteParams<TestCaseParameters>('/product/:product/testCase/edit/:testCaseId');
  const { data: testCase } = useQuery<TestCaseTraceWithResult | undefined>(
    `testCase-${testCaseId}`,
    () => itemsApi.queryById<TestCaseTraceWithResult | undefined>(companyId, product.id, testCaseId),
    { cacheTime: 0 },
  );

  const initialFormValues = useMemo<NativeTestCaseContainerFormType | undefined>(() => {
    if (!testCase) {
      return undefined;
    }

    const currentNativeTestCase = retrieveNativeTestCases(configs).find((item) => item.type === testCase.type);
    if (!currentNativeTestCase) {
      return undefined;
    }

    const relatedElements: ProductRequirementFormRelatedElementsType = {
      req1: [],
      req2: [],
      req3: [],
    };

    testCase['parent' as keyof Pick<TestCaseTraceWithResult, 'parent'>]?.forEach((item: TestCaseTraceWithResult) => {
      (
        relatedElements[item.type as keyof ProductRequirementFormRelatedElementsType] as Array<FormRelatedElementType>
      ).push(toFormRelatedElementType(item));
    });

    return {
      title: testCase.title,
      type: toTestCaseSegmentedControlType(currentNativeTestCase),
      description: testCase.description || '',
      statusLabel: { label: testCase.statusLabel, value: testCase.statusLabel },
      ...relatedElements,
      attachments: testCase.attachments,
    };
  }, [configs, testCase]);

  const [validationErrors, setValidationErrors] = useState<Array<RequestValidationError>>([]);

  const { mutate, isLoading } = useMutation(
    ({ payload }: { payload: TestCasePayload }) =>
      itemsApi.update(companyId, product.id, { data: { ...payload, id: testCaseId } }),
    {
      onSuccess: () => {
        setValidationErrors([]);
        navigate(`/product/${product.id}/requirement`);
      },
      onError: (error: any) => {
        setValidationErrors(filterValidationErrors(error));
        scrollWindowToTop();
      },
    },
  );

  const submitTestCasePayload = async (testCasePayload: TestCasePayload) => mutate({ payload: testCasePayload });

  const cancelCreateTestCase = (): void => {
    navigate(`/product/${product.id}/requirement`);
  };

  if (!initialFormValues) {
    return <Loading />;
  }

  return (
    <NativeTestCaseContainer
      product={product}
      title={'Edit test case'}
      confirmText={'Save changes'}
      testCaseId={testCase?.id}
      testCaseCode={testCase?.code ?? ''}
      testResult={testCase?.testResult}
      isSubmittingPayload={isLoading || loading}
      submitTestCasePayload={submitTestCasePayload}
      cancelCreateTestCase={cancelCreateTestCase}
      validationErrors={validationErrors}
      initialValues={initialFormValues}
    />
  );
};

export default EditNativeTestCase;
