import { Payload } from '../types/payload';
import { Product } from '../types/product';
import api from './index';

const productApi = {
  async fetch(company: number, product: string): Promise<Product> {
    const allProducts = await this.query(company);
    const selected = allProducts.find((p) => p.id === product);

    if (selected) {
      return selected;
    }

    throw new Error('Product not found');
  },
  query(company: number): Promise<Product[]> {
    return api
      .get(`/company/${company}/product-development/product`)
      .then((res) => res.data as Payload<Product[]>)
      .then((res) => res.data)
      .then((products) =>
        products.map((product) => ({
          ...product,
          type: product.type === '' ? undefined : product.type,
        })),
      )
      .catch((e) => {
        console.error(e);
        return [];
      });
  },
  async create(company: number, product: Omit<Product, 'id'>): Promise<Product> {
    const response = await api.post(`/company/${company}/product-development/product`, {
      data: product,
    });
    return (response.data as Payload<Product>).data;
  },
  async delete(company: number, product: string): Promise<boolean> {
    const response = await api.delete(`/company/${company}/product-development/product/${product}`);
    return response.status === 204;
  },
  async update(company: number, product: string, payload: Pick<Product, 'name' | 'tags'>): Promise<void> {
    await api.put(`/company/${company}/product-development/product/${product}`, { data: payload });
  },
};

export default productApi;
