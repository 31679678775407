import React from 'react';

import { QButton, QBox, QStack } from '@qualio/ui-components';

import { HorizontalLine, MessageAlert } from '..';
import { FormHeader } from '../../components_new/FormHeader';
import { Loading } from '../../components_new/Loading';
import { Product } from '../../types/product';

type BasicFormProps = {
  title: string;
  product?: Product;
  isLoading?: boolean;
  isError: boolean;
  errorText?: string;
  cancelText: string;
  confirmText: string;
  confirmButtonLoading?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  isBusy?: boolean;
};

const BasicForm: React.FC<BasicFormProps> = ({
  children,
  title,
  product,
  isLoading = false,
  isError,
  errorText = 'Something went wrong',
  cancelText,
  confirmText,
  confirmButtonLoading,
  onCancel,
  onConfirm,
  isBusy = false,
}) => {
  return (
    <div>
      {!isLoading && <FormHeader title={title} productName={product?.name ?? ''} onClose={onCancel} />}

      {isError && <MessageAlert data-cy="error-alert" title={errorText} type="warning" />}

      {isLoading ? (
        <QBox mb={8}>
          <Loading />
        </QBox>
      ) : (
        children
      )}

      <HorizontalLine />
      <QStack spacing="20px" direction="row" justifyContent="flex-end">
        <QButton variant="link" onClick={onCancel} isDisabled={isLoading} data-cy="basic-form-cancel">
          {cancelText}
        </QButton>
        <QButton
          onClick={onConfirm}
          isDisabled={isLoading || isBusy || confirmButtonLoading}
          isLoading={!!confirmButtonLoading}
          data-cy="basic-form-confirm"
        >
          {confirmText}
        </QButton>
      </QStack>
    </div>
  );
};

export default BasicForm;
