import React from 'react';

import { QFormControl } from '@qualio/ui-components';
import { Controller } from 'react-hook-form';

import { ResourceLibraryLookup } from './ResourceLibraryLookup';

type ResourceCategoryFormFieldProps = {
  name: string;
  label: string;
};

export const ResourceCategoryFormField: React.FC<ResourceCategoryFormFieldProps> = ({ name, label }) => {
  return (
    <QFormControl label={label} helper="Select your category resource from the Resource Library. ">
      <Controller
        name={name}
        render={({ ref, ...props }) => (
          <ResourceLibraryLookup
            {...props}
            name={name}
            data-cy={name}
            resource="registry-category"
            title="Select category resource"
            action="Set resource"
          />
        )}
      />
    </QFormControl>
  );
};
