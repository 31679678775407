import React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { QButton } from '@qualio/ui-components';
import { FormProvider, useForm } from 'react-hook-form';

import { FormContainer, MessageAlert, RequirementLevelsForm } from '../../../components';
import { ButtonContainer } from '../../../components/ButtonContainer';
import { getExistingConfigPrefixes, useQualityConfigs } from '../../../context/qualityConfigs';
import { BaseQualityType } from '../../../types/baseQualityItem';
import { RequestValidationError } from '../../../types/requestValidationError';
import {
  requirementConfigurationSchemaProvider,
  RequirementConfigurationType,
} from '../../../types/requirementConfiguration';

type RequirementSettingsFormProps = {
  initialValue: RequirementConfigurationType;
  onSubmit: (data: RequirementConfigurationType) => void;
  onDelete: (data: BaseQualityType) => void;
  submitIsLoading: boolean;
  archivePrefixes: Array<string>;
  validationErrors: Array<RequestValidationError>;
};

const RequirementSettingsForm: React.FC<RequirementSettingsFormProps> = ({
  initialValue,
  onSubmit,
  onDelete,
  submitIsLoading,
  archivePrefixes,
  validationErrors,
}) => {
  const { configs } = useQualityConfigs();

  const methods = useForm<RequirementConfigurationType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValue,
    resolver: zodResolver(requirementConfigurationSchemaProvider(getExistingConfigPrefixes(configs), archivePrefixes)),
  });

  return (
    <FormProvider {...methods}>
      <FormContainer>
        {!!Object.keys(methods.errors).length && (
          <MessageAlert type="error" title="There were some errors" data-cy="error-alert" />
        )}
        <RequirementLevelsForm onDelete={onDelete} validationErrors={validationErrors} />
        <ButtonContainer>
          <QButton
            data-cy="save-requirements-button"
            onClick={methods.handleSubmit(onSubmit)}
            isLoading={submitIsLoading}
          >
            Save
          </QButton>
        </ButtonContainer>
      </FormContainer>
    </FormProvider>
  );
};

export default RequirementSettingsForm;
