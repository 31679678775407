import React from 'react';

import { QFormControl } from '@qualio/ui-components';
import { Controller } from 'react-hook-form';

import { Category } from '../../types/baseConfiguration';
import { ResourceLibraryLookup, ResourceLibraryLookupProps } from './ResourceLibraryLookup';

type DesignElementResourceItemFormFieldProps = {
  resourceSubType: ResourceLibraryLookupProps['resourceSubType'];
  name: string;
  label: string;
  title: string;
  action: string;
};

export const DesignElementResourceItemFormField: React.FC<DesignElementResourceItemFormFieldProps> = ({
  resourceSubType,
  name,
  title,
  action,
  label,
}) => {
  return (
    <QFormControl label={label}>
      <Controller
        name={name}
        render={({ ref, ...props }) => (
          <ResourceLibraryLookup
            {...props}
            name={name}
            data-cy={name}
            resource="registry-item"
            resourceSubType={resourceSubType}
            title={title}
            action={action}
            value={props.value ? [props.value] : undefined}
            onChange={(category?: Category[]) => props.onChange(category?.[0] ?? undefined)}
          />
        )}
      />
    </QFormControl>
  );
};
