import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { QButton, deprecated, QHeading, QMenuButton, QMenuItem, QStack } from '@qualio/ui-components';
import { useNavigate } from 'react-router-dom';

import { Container, UserPermissions } from '../../components';
import { DeleteConfirmationModal } from '../../components/DeleteDesignElementModal/DeleteConfirmationModal';
import useHasPermission from '../../components/UserGroupToggle/hooks/useHasPermission';
import { ClickPropagationBarrier } from '../../components_new/ClickPropogateBarrier/ClickPropogateBarrier';
import { ProductEmptyState } from '../../components_new/EmptyState/Product';
import { PageTable } from '../../components_new/PageTable/PageTable';
import { useAnalytics } from '../../context/analytics';
import { useCompanyId } from '../../context/CurrentUserContext';
import { useProducts } from '../../hooks/useProducts';
import { Product } from '../../types/product';

const ProductList: React.FC = () => {
  const company = useCompanyId();
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const permissions = useHasPermission([UserPermissions.MANAGE_PRODUCT]);
  const [productToBeDeleted, setProductToBeDeleted] = useState<Product | undefined>(undefined);

  useEffect(() => {
    analytics.track('PD List products', { company });
  }, [analytics, company]);

  const canManageProduct = useMemo(() => permissions[UserPermissions.MANAGE_PRODUCT], [permissions]);
  const createProductClick = useCallback(() => navigate('/product/create'), [navigate]);

  const { products: data, isLoading, deleteProduct, isDeleting } = useProducts();

  const columns: Array<deprecated.QDataColumn> = [
    {
      Header: 'Product Name',
      accessor: 'title',
      width: '100%',
    },
    {
      Header: '',
      accessor: 'menu',
      disableSortBy: true,
    },
  ];

  const onRowClick = useCallback((data: deprecated.QDataRow) => navigate(`/product/${data.id}`), [navigate]);
  const onEditClick = useCallback((productId: string) => navigate(`/product/edit/${productId}`), [navigate]);

  const manualSortBy = {
    defaultSortByColumn: [{ id: 'title', desc: false }],
  };

  const products: Array<deprecated.QDataRow> = useMemo<Array<deprecated.QDataRow>>(() => {
    return data.map((item) => ({
      id: item.id,
      title: <span data-cy={item.id}>{item.name}</span>,
      menu: canManageProduct && (
        <ClickPropagationBarrier data-cy={`product-action-${item.id}`}>
          <QMenuButton variant="icon" buttonLabel="options" iconName="MoreVertical">
            <QMenuItem onClick={() => onEditClick(item.id)} data-cy={`edit-product-${item.id}`}>
              Edit
            </QMenuItem>
            <QMenuItem
              color="red.500"
              onClick={() => setProductToBeDeleted(item)}
              data-cy={`delete-product-${item.id}`}
            >
              Delete
            </QMenuItem>
          </QMenuButton>
        </ClickPropagationBarrier>
      ),
    }));
  }, [data, canManageProduct, onEditClick]);

  return (
    <Container>
      <QStack alignContent="center" mb="24px">
        <QStack direction="row" justifyContent="space-between">
          <QHeading size="lg" data-cy="heading">
            Products
          </QHeading>
          {!isLoading && !isDeleting && products.length > 0 && canManageProduct && (
            <QButton data-cy="create-product" onClick={createProductClick}>
              Create Product
            </QButton>
          )}
        </QStack>
      </QStack>
      <div data-cy={'products-table'}>
        <PageTable
          isLoading={isLoading || isDeleting}
          columns={columns}
          data={products}
          data-cy="products-table"
          onRowClick={onRowClick}
          emptyStateComponent={<ProductEmptyState canManageProduct={canManageProduct} onClick={createProductClick} />}
          manualSortBy={manualSortBy}
        />
      </div>
      <DeleteConfirmationModal
        onConfirm={() => {
          productToBeDeleted && deleteProduct(productToBeDeleted?.id);
          setProductToBeDeleted(undefined);
        }}
        onClose={() => setProductToBeDeleted(undefined)}
        confirmationDetails={
          productToBeDeleted
            ? {
                title: 'Delete product',
                message: (
                  <>
                    Are you sure you want to delete <b>{productToBeDeleted.name}</b>?
                  </>
                ),
              }
            : undefined
        }
      />
    </Container>
  );
};

export default ProductList;
