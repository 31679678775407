import { AxiosError } from 'axios';

import { BaseQualityType } from '../types/baseQualityItem';
import { Payload } from '../types/payload';
import { QualityTypeConfigWithType } from '../types/qualityTypeConfig';
import { DataValidationIssue, RequestValidationError } from '../types/requestValidationError';
import api from './index';

type ServerSideValidationError = {
  message: string;
  path: Array<string>;
};
type ServerSideValidationErrors = {
  errors: Array<ServerSideValidationError>;
};

const catchServerSideValidationErrors = (path?: string) => (error: AxiosError<ServerSideValidationErrors>) => {
  if (error.response?.status === 400 && error.response.data?.errors) {
    throw new RequestValidationError(
      path ?? '',
      error.response.data.errors.map(
        (item: ServerSideValidationError) => new DataValidationIssue(item.path.pop() ?? '', item.message),
      ),
    );
  }
  throw error;
};

const configApi = {
  query(company: number, product: string): Promise<QualityTypeConfigWithType[]> {
    return api
      .get<Payload<QualityTypeConfigWithType[]>>(`/company/${company}/product-development/product/${product}/config`)
      .then((res) => res.data.data);
  },
  retrieveConfig<T>(company: number, product: string, type: BaseQualityType): Promise<T> {
    return api
      .get<Payload<T>>(`/company/${company}/product-development/product/${product}/config/${type}`)
      .then((res) => res.data.data);
  },
  createConfig<T>(company: number, product: string, type: BaseQualityType, payload: T): Promise<T> {
    return api
      .post<Payload<T>>(`/company/${company}/product-development/product/${product}/config`, {
        data: { ...payload, type },
      })
      .then((res) => res.data.data)
      .catch(catchServerSideValidationErrors(type));
  },
  updateConfig<T>(company: number, product: string, type: BaseQualityType, payload: T): Promise<T> {
    return api
      .put<Payload<T>>(`/company/${company}/product-development/product/${product}/config/${type}`, {
        data: { ...payload, type },
      })
      .then((res) => res.data.data)
      .catch(catchServerSideValidationErrors(type));
  },
  deleteConfig(company: number, product: string, type: BaseQualityType): Promise<void> {
    return api
      .delete(`/company/${company}/product-development/product/${product}/config/${type}`)
      .then(() => undefined);
  },
  retrieveArchivePrefixes(company: number, product: string): Promise<Array<string>> {
    return api
      .get<Payload<Array<string>>>(`/company/${company}/product-development/product/${product}/archivePrefixes`)
      .then((res) => res.data.data);
  },
};

export default configApi;
