import { QualityTypeConfigWithIntegrations } from '../../context/qualityConfigTypes';
import { DesignElementType } from '../../types/baseQualityItem';
import { PolicyIssues } from '../../types/openIssue';
import { Payload } from '../../types/payload';
import {
  BaseQualityTypeConfig,
  QualityTypeConfigWithType,
  RiskLevel,
  RiskQualityTypeConfig,
} from '../../types/qualityTypeConfig';
import { SourceConfigResponse } from '../../types/sourceConfig';
import { makeResponse } from './generic';

export const qualityTypeConfigs: QualityTypeConfigWithType[] = [
  {
    codePrefix: 'UN',
    label: 'User Need',
    workflow: {
      states: [
        {
          name: 'Verification',
          isReleasable: false,
        },
        {
          name: 'Design Review',
          isReleasable: false,
        },
        {
          name: 'Risk Assessment',
          isReleasable: false,
        },
        {
          name: 'Validated',
          isReleasable: true,
        },
      ],
    },
    codeStrategy: 'GENERATED',
    policy: ['REQUIRES_CHILD', 'UNTESTED_REQUIREMENT'] as PolicyIssues[],
    type: DesignElementType.REQ1,
  },
  {
    codePrefix: 'US',
    label: 'User Story',
    workflow: {
      states: [
        {
          name: 'Verification',
          isReleasable: false,
        },
        {
          name: 'DESIGN',
          isReleasable: false,
        },
        {
          name: 'IN PROGRESS',
          isReleasable: false,
        },
        {
          name: 'VALIDATION',
          isReleasable: false,
        },
        {
          name: 'Done',
          isReleasable: true,
        },
      ],
    },
    codeStrategy: 'GENERATED',
    type: DesignElementType.REQ2,
  },
  {
    codePrefix: 'TC',
    label: 'Test',
    workflow: {
      states: [
        {
          name: 'Failed',
          isReleasable: false,
        },
        {
          name: 'Skipped',
          isReleasable: false,
        },
        {
          name: 'Passed',
          isReleasable: true,
        },
        {
          name: 'NA',
          isReleasable: true,
        },
      ],
    },
    codeStrategy: 'GENERATED',
    type: DesignElementType.TEST_CASE,
  },
  {
    codePrefix: 'VAL',
    label: 'Validation',
    workflow: {
      states: [
        {
          name: 'Failed',
          isReleasable: false,
        },
        {
          name: 'Passed',
          isReleasable: true,
        },
      ],
    },
    codeStrategy: 'GENERATED',
    type: DesignElementType.TEST_CASE_1,
  },
  {
    codePrefix: 'VER',
    label: 'Verification',
    workflow: {
      states: [
        {
          name: 'Failed',
          isReleasable: false,
        },
        {
          name: 'Passed',
          isReleasable: true,
        },
      ],
    },
    codeStrategy: 'GENERATED',
    type: DesignElementType.TEST_CASE_2,
  },
  {
    workflow: {
      states: [
        {
          name: 'Passed',
          isReleasable: true,
        },
        {
          name: 'Failed',
          isReleasable: false,
        },
      ],
    },
    codeStrategy: 'NONE',
    label: 'Result',
    type: DesignElementType.TEST_LOG,
  },
];

export const req3Config: QualityTypeConfigWithType = {
  codePrefix: 'DO',
  label: 'Design Output',
  workflow: {
    states: [
      {
        name: 'Verification',
        isReleasable: false,
      },
      {
        name: 'Design Review',
        isReleasable: false,
      },
      {
        name: 'Risk Assessment',
        isReleasable: false,
      },
      {
        name: 'Validated',
        isReleasable: true,
      },
    ],
  },
  codeStrategy: 'GENERATED',
  policy: ['REQUIRES_CHILD', 'UNTESTED_REQUIREMENT'] as PolicyIssues[],
  type: DesignElementType.REQ3,
};

export const req1Config: QualityTypeConfigWithType = {
  codePrefix: 'UN',
  label: 'User Need',
  workflow: {
    states: [
      {
        name: 'Verification',
        isReleasable: false,
      },
      {
        name: 'Design Review',
        isReleasable: false,
      },
      {
        name: 'Risk Assessment',
        isReleasable: false,
      },
      {
        name: 'Validated',
        isReleasable: true,
      },
    ],
  },
  codeStrategy: 'GENERATED',
  policy: ['REQUIRES_CHILD', 'UNTESTED_REQUIREMENT'] as PolicyIssues[],
  type: DesignElementType.REQ1,
};

export const req2Config: QualityTypeConfigWithType = {
  codePrefix: 'US',
  label: 'User Story',
  workflow: {
    states: [
      {
        name: 'Verification',
        isReleasable: false,
      },
      {
        name: 'Design Review',
        isReleasable: false,
      },
      {
        name: 'Risk Assessment',
        isReleasable: false,
      },
      {
        name: 'Validated',
        isReleasable: true,
      },
    ],
  },
  codeStrategy: 'GENERATED',
  type: DesignElementType.REQ2,
};

export const testCaseConfig: QualityTypeConfigWithType = {
  codePrefix: 'TC',
  label: 'Test',
  workflow: {
    states: [
      {
        name: 'Draft',
        isReleasable: false,
      },
      {
        name: 'Done',
        isReleasable: false,
      },
    ],
  },
  codeStrategy: 'GENERATED',
  type: DesignElementType.TEST_CASE,
};

export const testCase1Config: QualityTypeConfigWithType = {
  codePrefix: 'VAL',
  label: 'Validation',
  workflow: {
    states: [
      {
        name: 'Draft',
        isReleasable: false,
      },
      {
        name: 'Done',
        isReleasable: false,
      },
    ],
  },
  codeStrategy: 'GENERATED',
  policy: ['REQUIRES_CHILD', 'UNTESTED_REQUIREMENT'] as PolicyIssues[],
  type: DesignElementType.TEST_CASE_1,
};

export const testCase2Config: QualityTypeConfigWithType = {
  codePrefix: 'VER',
  label: 'Verification',
  workflow: {
    states: [
      {
        name: 'Draft',
        isReleasable: false,
      },
      {
        name: 'Done',
        isReleasable: false,
      },
    ],
  },
  codeStrategy: 'GENERATED',
  policy: ['REQUIRES_CHILD', 'UNTESTED_REQUIREMENT'] as PolicyIssues[],
  type: DesignElementType.TEST_CASE_2,
};

export const testCase3Config: QualityTypeConfigWithType = {
  codePrefix: 'TC3',
  label: 'Test Case 3',
  workflow: {
    states: [
      {
        name: 'Draft',
        isReleasable: false,
      },
      {
        name: 'Done',
        isReleasable: false,
      },
    ],
  },
  codeStrategy: 'GENERATED',
  type: DesignElementType.TEST_CASE_3,
};

export const isoOnlyRiskConfig: RiskQualityTypeConfig & { type: DesignElementType.RISK } = {
  label: 'Risk',
  codePrefix: 'RK',
  codeStrategy: 'GENERATED',
  assessment: {
    iso: {
      severity: ['Negligible', 'Minor', 'Serious', 'Major', 'Critical'],
      acceptableLevels: ['Low'],
      levelMapping: [
        ['Low', 'Low', 'Low', 'Low', 'Low'],
        ['Low', 'Low', 'Low', 'Medium', 'Medium'],
        ['Low', 'Low', 'Medium', 'Medium', 'High'],
        ['Low', 'Medium', 'Medium', 'High', 'High'],
        ['Medium', 'Medium', 'High', 'High', 'High'],
      ],
      probability: ['Improbable', 'Remote', 'Occasional', 'Probable', 'Frequent'],
      levels: ['Low', 'Medium', 'High'],
    },
  },
  workflow: {
    states: [
      {
        name: 'To Do',
        isReleasable: false,
      },
      {
        name: 'Design',
        isReleasable: false,
      },
      {
        name: 'Implementation',
        isReleasable: false,
      },
      {
        name: 'Verified',
        isReleasable: false,
      },
      {
        name: 'Validated',
        isReleasable: true,
      },
    ],
  },
  type: DesignElementType.RISK,
};

export const isoOnly3x3RiskConfig: RiskQualityTypeConfig & { type: DesignElementType.RISK } = {
  ...isoOnlyRiskConfig,
  assessment: {
    iso: {
      ...isoOnlyRiskConfig.assessment.iso,
      severity: ['Negligible', 'Minor', 'Serious'],
      levelMapping: [
        ['Low', 'Low', 'Medium'],
        ['Low', 'Medium', 'Medium'],
        ['Medium', 'Medium', 'High'],
      ],
      probability: ['Improbable', 'Remote', 'Occasional'],
    },
  },
};

export const fmeaRiskConfig: RiskQualityTypeConfig & { type: DesignElementType.RISK } = {
  label: 'Risk',
  codePrefix: 'RK',
  codeStrategy: 'GENERATED',
  assessment: {
    iso: {
      severity: ['Negligible', 'Minor', 'Serious', 'Major', 'Critical'],
      acceptableLevels: ['Low'],
      levelMapping: [
        ['Low', 'Low', 'Low', 'Low', 'Low'],
        ['Low', 'Low', 'Low', 'Medium', 'Medium'],
        ['Low', 'Low', 'Medium', 'Medium', 'High'],
        ['Low', 'Medium', 'Medium', 'High', 'High'],
        ['Medium', 'Medium', 'High', 'High', 'High'],
      ],
      probability: ['Improbable', 'Remote', 'Occasional', 'Probable', 'Frequent'],
      levels: ['Low', 'Medium', 'High'],
    },
    fmea: {
      scaleMaxValue: 10,
      mitigationThreshold: 100,
    },
  },
  workflow: {
    states: [
      {
        name: 'To Do',
        isReleasable: false,
      },
      {
        name: 'Design',
        isReleasable: false,
      },
      {
        name: 'Implementation',
        isReleasable: false,
      },
      {
        name: 'Verified',
        isReleasable: false,
      },
      {
        name: 'Validated',
        isReleasable: true,
      },
    ],
  },
  type: DesignElementType.RISK,
};

const oldRiskConfig: BaseQualityTypeConfig & { riskLevel: RiskLevel; type: DesignElementType.RISK } = {
  label: 'Risk',
  codePrefix: 'RK',
  codeStrategy: 'GENERATED',
  riskLevel: {
    severity: ['Negligible', 'Minor', 'Serious', 'Major', 'Critical'],
    acceptableLevels: ['Low'],
    levelMapping: [
      ['Low', 'Low', 'Low', 'Low', 'Low'],
      ['Low', 'Low', 'Low', 'Medium', 'Medium'],
      ['Low', 'Low', 'Medium', 'Medium', 'High'],
      ['Low', 'Medium', 'Medium', 'High', 'High'],
      ['Medium', 'Medium', 'High', 'High', 'High'],
    ],
    probability: ['Improbable', 'Remote', 'Occasional', 'Probable', 'Frequent'],
    levels: ['Low', 'Medium', 'High'],
  },
  workflow: {
    states: [
      {
        name: 'To Do',
        isReleasable: false,
      },
      {
        name: 'Design',
        isReleasable: false,
      },
      {
        name: 'Implementation',
        isReleasable: false,
      },
      {
        name: 'Verified',
        isReleasable: false,
      },
      {
        name: 'Validated',
        isReleasable: true,
      },
    ],
  },
  type: DesignElementType.RISK,
};

export const getAllConfigsResponse: Payload<QualityTypeConfigWithType[]> = makeResponse([
  ...qualityTypeConfigs,
  isoOnlyRiskConfig,
]);

export const getAllConfigsResponseWithIntegrations: Payload<QualityTypeConfigWithIntegrations[]> = makeResponse([
  ...[qualityTypeConfigs[0], qualityTypeConfigs[1], qualityTypeConfigs[2]].map((config) => ({
    ...config,
    integrations: ['jira'],
  })),
]);

export const getAllConfigsRequirementResponse: Payload<QualityTypeConfigWithType[]> = makeResponse([
  ...qualityTypeConfigs,
  req3Config,
  isoOnlyRiskConfig,
]);

export const getAllReqConfigsWithTestCaseConfigRequirementResponse: Payload<QualityTypeConfigWithType[]> = makeResponse(
  [req1Config, req2Config, req3Config, testCaseConfig, isoOnlyRiskConfig],
);

export const getReq3ConfigsWithTestCase3ConfigRequirementResponse: Payload<QualityTypeConfigWithType[]> = makeResponse([
  req3Config,
  testCase3Config,
]);

export const getAllConfigsTestCaseResponse: Payload<QualityTypeConfigWithType[]> = makeResponse([
  req1Config,
  req2Config,
  testCase1Config,
  testCase2Config,
]);

export const getArchivePrefixesResponse: Payload<Array<string>> = makeResponse(['UNN', 'USS']);

export const getAllConfigsWithFMEAResponse: Payload<QualityTypeConfigWithType[]> = makeResponse([
  ...qualityTypeConfigs,
  fmeaRiskConfig,
]);

export const getAllConfigsWithOldRiskResponse: Payload<QualityTypeConfigWithType[]> = makeResponse([
  ...qualityTypeConfigs,
  oldRiskConfig,
]);

export const fmeaConfigResponse: Payload<RiskQualityTypeConfig> = makeResponse(fmeaRiskConfig);

export const emptySourceConfigResponse: Payload<SourceConfigResponse[]> = makeResponse([]);
