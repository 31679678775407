import React, { useMemo } from 'react';

import { QDivider, QFlex, QText } from '@qualio/ui-components';
import { sanitize } from 'dompurify';

import { Relation } from '../../../components/QualityItemDetails/types';
import { DesignElementType } from '../../../types/baseQualityItem';
import { ChangeHistory } from '../../../types/changeHistory';
import { AttachmentWithIdType } from '../../../types/generatePresignedUrl';
import { BaseQualityItemTrace } from '../../../types/qualityItemTrace';
import { AttachmentDisplayElement } from '../../AttachmentDisplayElement/AttachmentDisplayElement';
import changeControlDetailsDrawerStyles from '../../ChangeControlDetailsDrawer/ChangeControlDetailsDrawer.module.less';
import DesignElementDisplay from '../../DesignElementDisplay/DesignElementDisplay';
import DesignElementGroup from '../../DesignElementGroup/DesignElementGroup';
import { ReviewDisplayElement } from '../../ReviewDisplayElement/ReviewDisplayElement';
import { mapRelationToDesignElementDisplayItem } from '../lib/mapRelationToDesignElementDisplayItem';

export const RequirementTestCaseDetailsDrawerBody: React.FC<{
  designElement: BaseQualityItemTrace;
  relatedDesignElements: Array<Relation>;
  reviewHistory: ChangeHistory;
  configsMap: Record<DesignElementType, string>;
  attachments: Array<AttachmentWithIdType>;
  reviewClick?: (id: string) => void;
  designElementClick: (designElementId: string, designElementVersion?: string) => void;
}> = ({
  designElement,
  relatedDesignElements,
  reviewHistory,
  configsMap,
  attachments,
  reviewClick,
  designElementClick,
  children,
}) => {
  const relatedItems = useMemo(() => {
    return relatedDesignElements.map((item) => mapRelationToDesignElementDisplayItem(item, configsMap));
  }, [relatedDesignElements, configsMap]);

  return (
    <>
      <QFlex
        data-cy={`design-element-drawer-description-${designElement.id}`}
        marginTop={5}
        marginBottom={5}
        rowGap={3}
        flexDirection={'column'}
      >
        <QText color="gray.700" fontSize={14} weight="semibold">
          Description
        </QText>
        {designElement.description && (
          <QText
            fontSize={14}
            dangerouslySetInnerHTML={{ __html: sanitize(designElement.description) }}
            className={`${changeControlDetailsDrawerStyles.stylizedDescription}`}
          />
        )}
      </QFlex>
      <QDivider />
      {attachments.length > 0 && (
        <>
          <QFlex data-cy={`design-element-drawer-attachments`} marginTop={5} marginBottom={5} flexDirection={'column'}>
            <QText color="gray.700" fontSize={14} weight="semibold">
              Attachments
            </QText>
            <AttachmentDisplayElement attachments={attachments} />
          </QFlex>
          <QDivider />
        </>
      )}
      {children}
      {relatedItems.length > 0 && (
        <>
          <QFlex
            data-cy={`design-element-drawer-related-items-${designElement.id}`}
            marginTop={5}
            marginBottom={5}
            flexDirection={'column'}
          >
            <QText fontSize={'md'} weight="semibold">
              Related Design Elements
            </QText>

            <DesignElementGroup
              elements={relatedItems}
              selectedElementComponent={DesignElementDisplay}
              elementCallBackFunction={designElementClick}
            />
          </QFlex>
          <QDivider />
        </>
      )}
      {reviewHistory.length > 0 && (
        <QFlex
          data-cy={`design-element-drawer-review-history-${designElement.id}`}
          marginTop={5}
          marginBottom={70}
          flexDirection={'column'}
        >
          <QText color="gray.700" fontSize={14} weight="semibold">
            Review History
          </QText>
          <ReviewDisplayElement reviews={reviewHistory} reviewClick={reviewClick} />
        </QFlex>
      )}
    </>
  );
};
