import { useToastProvider } from '@qualio/ui-components';
import { useMutation, useQuery } from 'react-query';

import productApi from '../api/product';
import { useCompanyId } from '../context/CurrentUserContext';
import { Product } from '../types/product';

type UseProducts = {
  isLoading: boolean;
  product: Product | undefined;
  products: Array<Product>;
  deleteProduct: (productId: string) => void;
  isDeleting: boolean;
};

type UseProductsProps = {
  productId?: string;
};

export const useProducts = (options?: UseProductsProps): UseProducts => {
  const company = useCompanyId();
  const { showToast } = useToastProvider();
  const {
    isLoading,
    data: products,
    refetch,
    isRefetching,
  } = useQuery(`query-${company}-products-list`, () =>
    productApi.query(company).then((response) => {
      return [...response].sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
    }),
  );

  let product: Product | undefined = undefined;
  if (!isLoading && options?.productId && products) {
    product = products.find((product) => product.id === options?.productId);
  }

  const { mutate: deleteProduct, isLoading: isDeleting } = useMutation(
    (productId: string) => productApi.delete(company, productId),
    {
      onSuccess: (data, productId) => {
        const product = (products as Array<Product>).find((item) => item.id === productId) as Product;
        showToast({
          title: 'Product deleted.',
          description: `You have deleted ${product.name}`,
          status: 'success',
          duration: 5000,
        });
        return refetch();
      },
      onError: (data: any, productId) => {
        const product = (products as Array<Product>).find((item) => item.id === productId) as Product;
        showToast({
          title: 'Unable to delete product.',
          description: `Unable to delete ${product.name}`,
          status: 'error',
          duration: 5000,
        });
      },
    },
  );

  return {
    isLoading: isRefetching || isLoading,
    product,
    products: products || [],
    deleteProduct,
    isDeleting,
  };
};
