import React from 'react';

import { QBox, QFormControl, QInput, QStack, QText } from '@qualio/ui-components';
import { Controller, DeepMap, FieldError, useFormContext } from 'react-hook-form';

import { deepGet } from '../../deepGet';
import { Card } from '../Card';
import ConfigStatuses from '../ConfigStatuses/ConfigStatuses';
import { FeatureFlags } from '../FeatureToggle/FeatureToggle.enum';
import useFeatureFlag from '../FeatureToggle/hooks/useFeatureFlag';
import { ResourceCategoryFormField } from '../QLookup';
import { BasicConfigurationBody } from './BasicConfiguration.styles';

type BasicConfigurationProps = {
  sectionTitle: string;
  controllerNamePrefixWithDot?: string;
  showSection?: boolean;
  type: string;
} & React.HTMLAttributes<HTMLDivElement>;

const BasicConfiguration: React.FC<BasicConfigurationProps> = ({
  sectionTitle,
  controllerNamePrefixWithDot = '',
  showSection = true,
  children,
  type,
  ...props
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  const features = useFeatureFlag([FeatureFlags.DESIGN_CONTROLS_CATEGORIES]);

  const prefix = `${controllerNamePrefixWithDot}prefix`;
  const label = `${controllerNamePrefixWithDot}label`;
  const categories = `${controllerNamePrefixWithDot}category`;
  const statuses = `${controllerNamePrefixWithDot}statuses`;

  const getFieldsError = (
    formErrors: DeepMap<Record<string, any>, FieldError>,
    objectPath: string,
  ): { message: string } | undefined => {
    return deepGet(formErrors, objectPath) as { message: string } | undefined;
  };

  const prefixError = getFieldsError(errors, prefix);
  const labelError = getFieldsError(errors, label);

  return (
    <Card {...props} noPadding={true} className="basic-configuration-card">
      <BasicConfigurationBody>
        <QStack direction="row" justifyContent="space-between">
          <QText fontSize="xl" fontWeight={400}>
            {sectionTitle}
          </QText>
        </QStack>
        {showSection && (
          <>
            <section>
              <QStack gap={4}>
                <QStack direction={'row'}>
                  <QBox w={100}>
                    <QFormControl label={'Prefix'} isInvalid={!!prefixError} error={prefixError?.message}>
                      <Controller
                        name={prefix}
                        render={({ onChange, value }) => <QInput onChange={onChange} value={value} data-cy={prefix} />}
                      />
                    </QFormControl>
                  </QBox>
                  <QFormControl label={'Label'} isInvalid={!!labelError} error={labelError?.message}>
                    <Controller
                      data-cy={prefix}
                      name={label}
                      render={({ onChange, value }) => <QInput onChange={onChange} value={value} data-cy={label} />}
                    />
                  </QFormControl>
                </QStack>
                {features[FeatureFlags.DESIGN_CONTROLS_CATEGORIES] && (
                  <ResourceCategoryFormField name={categories} label="Category resource" />
                )}
              </QStack>
            </section>
            <section>
              <QFormControl label={'Statuses'}>
                <ConfigStatuses name={statuses} />
              </QFormControl>
            </section>
          </>
        )}
      </BasicConfigurationBody>
      {children}
    </Card>
  );
};

export default BasicConfiguration;
