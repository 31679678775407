import React, { useMemo } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { QButton } from '@qualio/ui-components';
import { FormProvider, useForm } from 'react-hook-form';

import { FormContainer, MessageAlert } from '../../../components';
import { ButtonContainer } from '../../../components/ButtonContainer';
import EditTestCaseConfigurationsContainer from '../../../components/TestCaseConfigurations/Edit/EditTestCaseConfigurationsContainer';
import { RequirementAndTestCaseTupleType } from '../../../components/TestCaseConfigurations/TestCaseConfigurations.types';
import { getExistingConfigPrefixes, useQualityConfigs } from '../../../context/qualityConfigs';
import { BaseConfigurationType } from '../../../types/baseConfiguration';
import { DesignElementType, TestCaseType } from '../../../types/baseQualityItem';
import { RequestValidationError } from '../../../types/requestValidationError';
import {
  TestCaseConfigurationType,
  testCaseTypeConfigurationSchemaProvider,
} from '../../../types/testCaseConfiguration';

type TestCasesSettingsFormProps = {
  initialValue: TestCaseConfigurationType;
  onDelete: (data: TestCaseType) => void;
  onSubmit: (data: TestCaseConfigurationType) => void;
  submitIsLoading: boolean;
  archivePrefixes: Array<string>;
  validationErrors: Array<RequestValidationError>;
};

const TestCasesSettingsForm: React.FC<TestCasesSettingsFormProps> = ({
  initialValue,
  onSubmit,
  submitIsLoading,
  archivePrefixes,
  validationErrors,
  onDelete,
}) => {
  const { configs } = useQualityConfigs();
  const requirementAndTestCaseTuples = useMemo(() => {
    const reqsWithTestCasesList: RequirementAndTestCaseTupleType[] = [];
    configs.forEach((config) => {
      const req: BaseConfigurationType = {
        prefix: config.codePrefix ?? '',
        label: config.label,
        statuses: [],
      };

      if (config.type === DesignElementType.REQ1) {
        reqsWithTestCasesList.push({
          requirement: req,
          testCase: DesignElementType.TEST_CASE_1,
        });
      }
      if (config.type === DesignElementType.REQ2) {
        reqsWithTestCasesList.push({
          requirement: req,
          testCase: DesignElementType.TEST_CASE_2,
        });
      }
      if (config.type === DesignElementType.REQ3) {
        reqsWithTestCasesList.push({
          requirement: req,
          testCase: DesignElementType.TEST_CASE_3,
        });
      }
    });
    return reqsWithTestCasesList;
  }, [configs]);

  const methods = useForm<TestCaseConfigurationType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValue,
    resolver: zodResolver(testCaseTypeConfigurationSchemaProvider(getExistingConfigPrefixes(configs), archivePrefixes)),
  });

  const { watch } = methods;

  const watchedTestCases = watch('testCases');

  const isSaveButtonDisabled = useMemo<boolean>(() => {
    return (
      watchedTestCases.testCase1 === undefined &&
      watchedTestCases.testCase2 === undefined &&
      watchedTestCases.testCase3 === undefined
    );
  }, [watchedTestCases]);

  const savedConfigTypes = Object.entries(initialValue.testCases)
    .filter(([key, value]) => !!value)
    .map(([key, value]) => key as TestCaseType);

  return (
    <FormProvider {...methods}>
      <FormContainer>
        {!!Object.keys(methods.errors).length && (
          <MessageAlert type="error" title="There were some errors" data-cy="error-alert" />
        )}
        {
          <EditTestCaseConfigurationsContainer
            validationErrors={validationErrors}
            requirementAndTestCaseTuples={requirementAndTestCaseTuples}
            onModalDeleteTestCaseCard={onDelete}
            savedConfigTypes={savedConfigTypes}
          />
        }
        <ButtonContainer>
          <QButton
            data-cy="save-testcases-button"
            isDisabled={isSaveButtonDisabled}
            onClick={methods.handleSubmit(onSubmit)}
            isLoading={submitIsLoading}
          >
            Save
          </QButton>
        </ButtonContainer>
      </FormContainer>
    </FormProvider>
  );
};

export default TestCasesSettingsForm;
